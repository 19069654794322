import React from "react";
import Masalapipe from "../../Images/resized images/Pipe Masala.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const MasalaPipe = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="FunCure Masala Masti"
        productname="FunCure Masala Masti"
        productrate="5/-"
        productdiscription="Masala Pipe, the snacks rich in masalas, will Make your evening tea delight with Extra Masala. These Snacks are full of carbohydrates and protein. The combination of Wheat Flour and Masalas awakens your Mood and your taste buds will be satisfied."
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home.

        "
        productingredients="Refined Wheat Flour,
        Edible Vegetable Oil (Palmolin oil or Cotton seed oil or Rice bran oil),
        Starch, Salt & Sugar"
        productimg={Masalapipe}
      />
    </>
  );
};

export default MasalaPipe;
