import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../src/Images/shrilogo.png";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <div className="position-static shadow header-background">
        <Navbar
          className="position-relative pt-3 pb-3 header-shadow"
          expand="lg"
          expanded={expanded}
        >
          <Container fluid>
            <Navbar.Brand className="logo-area position-absolute top-10">
              <Link className="" to="/">
                <img src={logo} alt="shyamg-logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : "expanded")}
              aria-controls="navbarScroll"
              className="toggle-left"
            />

            <Navbar.Collapse>
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <div className="d-flex">
                <Nav className="me-auto home-link" gap={3}>
                  <Link to="/" onClick={() => setExpanded(false)}>
                    Home
                  </Link>

                  <div className="dropdown">
                    <Link className="dropbtn" to="/about-us">
                      About Us <i className="bi bi-chevron-down mt-1"></i>
                    </Link>
                    <div className="dropdown-content">
                    <Link
                        to="/leadership"
                        className="dropbtn"
                        onClick={() => setExpanded(false)}
                      >
                        Leadership
                      </Link>
                      <Link
                        className="dropbtn"
                        to="/our-process"
                        onClick={() => setExpanded(false)}
                      >
                        Our Process
                      </Link>
                      <Link
                        to="/csr"
                        className="dropbtn"
                        onClick={() => setExpanded(false)}
                      >
                        CSR Activities
                      </Link>
                     
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link className="dropbtn" to="/products">
                      Products <i className="bi bi-chevron-down mt-1"></i>
                    </Link>
                    <div className="dropdown-content">
                      <Link
                        to="/chips-and-sticks"
                        className="dropbtn"
                        onClick={() => setExpanded(false)}
                      >
                        Chips & Sticks
                      </Link>
                      <Link
                        className="dropbtn"
                        to="/puf-and-rings"
                        onClick={() => setExpanded(false)}
                      >
                        Extruded Puff and Rings
                      </Link>
                      <Link
                        className="dropbtn"
                        to="/fried-snacks"
                        onClick={() => setExpanded(false)}
                      >
                        Fried Snacks
                      </Link>
                      {/* <Link className="dropbtn" to="/waahji-products">
                          WaahJi Products
                        </Link> */}
                      <Link
                        className="dropbtn"
                        to="/upcomming-products"
                        onClick={() => setExpanded(false)}
                      >
                        Upcoming Products
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link className="dropbtn">
                      Gallery <i className="bi bi-chevron-down mt-1"></i>
                    </Link>
                    <div className="dropdown-content">
                      <Link
                        to="/events"
                        className="dropbtn"
                        onClick={() => setExpanded(false)}
                      >
                        Events
                      </Link>
                      <Link
                        className="dropbtn"
                        to="/corporate-video"
                        onClick={() => setExpanded(false)}
                      >
                        Corporate Infrastructure
                      </Link>
                      {/* <Link
                        className="dropbtn"
                        to="/gallery"
                        onClick={() => setExpanded(false)}
                      >
                        Photos
                      </Link> */}
                    </div>
                  </div>

                  <Link
                    to="/industrial-visit"
                    onClick={() => setExpanded(false)}
                  >
                    Industrial Visit
                  </Link>
                  <Link to="/career"  onClick={() => setExpanded(false)}>career</Link>
                  <Link to="/distributor"  onClick={() => setExpanded(false)}>
                  Dealership
                  </Link>
                  <Link to="/contact-us" onClick={() => setExpanded(false)}>
                    contact us
                  </Link>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="header-repted-img d-none"></div>
    </>
  );
};

export default Header;
