import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SAMOSA from "../Images/products/new-shymG-produdts/2.png";
import MAST from "../Images/products/new-shymG-produdts/7.png";
import MASALAPIPE from "../Images/products/new-shymG-produdts/17.png";
import PASTAMASALAMUNCH from "../Images/products/new-shymG-produdts/6.png";
import TOMATOCHIPS from "../Images/products/new-shymG-produdts/14.png";
import SALTEDCHIPS from "../Images/products/new-shymG-produdts/15.png";
import ONIONCHIPS from "../Images/products/new-shymG-produdts/16.png"; 
import WIFI from "../Images/products/new-shymG-produdts/1.png"; 
import FUNCUREMASALMSTI from "../Images/products/new-shymG-produdts/10.png"; 
import TANGYTOMATO from "../Images/products/new-shymG-produdts/9.png"; 
import FRENCHBREAK from "../Images/products/new-shymG-produdts/12.png"; 
import FUNPOLOTANGY from "../Images/products/new-shymG-produdts/11.png"; 
import PIPESALTED from "../Images/products/new-shymG-produdts/4.png"; 
import CHOPCYMAST from "../Images/products/new-shymG-produdts/13.png";

const ProductsSlider = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {/* {posts.map((item) =>   
           <h2> {item.title} </h2>
          )} */}
        <div className="slider p-2">
          <Link to="/samosa-mixture-masala">
            <div className="products-single chips">
              <img className="custom-img" src={SAMOSA} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">
                  SAMOSA-MIXTURE-MASALA
                </button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/mast-mast-crispy-and-crunchy-bite">
            <div className="products-single chips">
              <img className="custom-img" src={MAST} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">
                  MAST MAST
                </button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/masala-chips">
            <div className="products-single chips">
              <img className="custom-img" src={MASALAPIPE} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">MASALA CHIPS</button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/pasta-masala-munch">
            <div className="products-single chips">
              <img className="custom-img" src={PASTAMASALAMUNCH} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">
                  PASTA MASALA MUNCH
                </button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/tomato-chips">
            <div className="products-single chips">
              <img className="custom-img" src={TOMATOCHIPS} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">TOMATO CHIPS</button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/salted-chips">
            <div className="products-single chips">
              <img className="custom-img" src={SALTEDCHIPS} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">SALTED CHIPS</button>
              </div>
            </div>
          </Link>
        </div>

        <div className="slider p-2">
          <Link to="/onion-chips">
            <div className="products-single chips">
              <img className="custom-img" src={ONIONCHIPS} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">ONION CHIPS</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/wifi-cone-crunchy-n-crispy">
            <div className="products-single chips">
              <img className="custom-img" src={WIFI} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">Wi-Fi</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/funcure-tangy-tomato">
            <div className="products-single chips">
              <img className="custom-img" src={TANGYTOMATO} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">FUNCURE TANGY TOMATO</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/funcure-masala-masti">
            <div className="products-single chips">
              <img className="custom-img" src={FUNCUREMASALMSTI} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">FUNCURE MASALA MASTI</button>
              </div>
            </div>
          </Link>
        </div> 
    
        <div className="slider p-2">
          <Link to="/french-o-bake">
            <div className="products-single chips">
              <img className="custom-img" src={FRENCHBREAK} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">FRENCH ‘O’ BAKE</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/fun-polo-tangy-tomato">
            <div className="products-single chips">
              <img className="custom-img" src={FUNPOLOTANGY} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">FUN POLO TANGY TOMATO</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/salted-pipe">
            <div className="products-single chips">
              <img className="custom-img" src={PIPESALTED} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">SALTED PIPE</button>
              </div>
            </div>
          </Link>
        </div> 

        <div className="slider p-2">
          <Link to="/chopcy-mast-masala">
            <div className="products-single chips">
              <img className="custom-img" src={CHOPCYMAST} alt="" />

              <div className="text-center">
                <button className="btn exp bg-white w-100">CHOPCY MAST MASALA</button>
              </div>
            </div>
          </Link>
        </div>


      </Slider>
    </>
  );
};

export default ProductsSlider;
