import React from "react";
import { productsDetailsarray } from "./const";
import Product from "../Component/Product";
import { Col } from "react-bootstrap";
import banner from "../Images/new-shyag-Banner/01.jpg";

const Products = () => {
  return (
    <>
      <div className="">
        <div className="row fixed-height-all-slider p-0 m-0">
          <div className="col-md-12 p-0 corner-sets">
            <img className="custom-img" src={banner} />
          </div>
        </div>
      </div>

      <div className="products-category products-section">
        <div className="container">
          <h2 className="text-center fs-1"> Featured Product</h2>
          <div className="border-design  text-center mb-2"> </div>
          <div className="row">
            {productsDetailsarray.map((data, index) => {
              return (
                <Col md={4} key={index}>
                  <Product
                    productsimg={data?.img}
                    productsname={data?.name}
                    // productsprice={data?.productsprice}
                    url={data?.url}
                  />
                </Col>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
