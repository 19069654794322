import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import OnianChips from "../../Images/products/new-shymG-produdts/16.png";

const Onianchips = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Onion Chips
             "
        productname="Onion Chips
        "
        productrate="5/-"
        productdiscription="Feel like having a tasty treat? ShyamG Snacks Onion Potato Chips are for you. Enjoy it with your favorite drink and beverage! This one is the perfect party snack for every time you are with your friends and family. So go ahead, and try these Onion Potato chips now!

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home. Edible Common Salt,Sugar Powder,"
        productingredients="Milk Products(Milk Solids, Cheese Powder) (14%), Spices & condiments(onion powder, Parsley Powder, Black Pepper powder) Natural & Nature Identical Flavoring Substances and Edible Starch. Edible Vegetable Oil(Palmolein oil, Cottonseed Oil),Potato(89%),"
        productimg={OnianChips}
      />
    </>
  );
};

export default Onianchips;
