import React from "react";
import Tomatochips from "../../Images/products/new-shymG-produdts/14.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const TomatoChips = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Tomato Chips
"
        productname="Tomato Chips
        "
        productrate="5/-, 10/-
        "
        productdiscription="These sweet and salty tangy tomato flavor chips with the irresistible flavor of tomatoes take you to the next level of taste. Crunchy and crispy tomato chips with mouth-watering taste of traditional tomato flavor

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home.

        "
        productingredients="Spices & Condiments (Chili Powder, Garlic Powder, Black Pepper Powder, Clove Powder, Cinnamon Powder) Natural & Nature Identical Flavoring Substances and Dextrose,
        "
        productimg={Tomatochips}
      />
    </>
  );
};

export default TomatoChips;
