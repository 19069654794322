import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail"; 
import Saltedpipe from "../../Images/products/new-shymG-produdts/4.png"

const SaltedPipe = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Salted Pipe
             "
        productname="Salted Pipe
        "
        productrate="5/-"
        productdiscription="Make your evening tea masala delight with Shyam-G Salted Pipe. Snacks full of carbohydrates and rich in protein. The combination of Rice and Corn Meal awakens your Mood and your taste buds will be satisfied

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal (33.2%),
        Corn Meal (33.2%) Edible Vegetable oil (palmolein And CottonSeed Oil) Sugar,
        Edible Common Salt,
        Spice & Condiments (Coriander Powder, Cumin Powder, Ginger Powder Fennel, Chili Powder, Black Pepper Powder, Clove Powder).
        Acidity Regulator (INS 330) and Anticaking Agent (NS 551)
        "
        productimg={Saltedpipe}
      />
    </>
  );
};

export default SaltedPipe;
