import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import distributorship from "../Images/distributorship.jpeg";

const Distributor = () => {
  return (
    <>
      <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Dealership</h2>
          </div>
        </div>
      </div>

      <div className="container">
        <Row>
        <Col md={6} className=" mt-3">
          <img className="w-100" src={distributorship}/>
        </Col>
          <Col md={6}>
          <h2 className="mt-4 mb-4">
            {" "}
            Be a Distributor For Shyam-G Snacks Across India
          </h2>
          <p className="text-justify fs-5 mb-0">
            {" "}
            Join hands with Shyam-G Snacks, a prominent name in the Indian
            snacks industry. Shree Shyam Snacks Food Private Limited, a leading
            snacks food manufacturer in India, invites dynamic individuals and
            businesses to partner with us as distributors.{" "}
          </p>
          <p className="text-justify fs-5 mb-0">
            Shyam-G Snacks has earned a stellar reputation for its top-quality
            snack products. We prioritize quality and hygiene, using only the
            finest raw materials to craft our delectable range. At Shyam-G, we
            offer diverse products, promising a satisfying snacking experience
            for all.
          </p>
          <p className="text-justify fs-5 mb-0">
            Our commitment to quality has earned us an impeccable reputation in
            the market. Shyam-G Snacks is synonymous with high-quality and
            diverse snack products. We take pride in using only the finest raw
            materials to craft our delicious range.
          </p>

          <p className="text-justify fs-5 mb-0">
            {" "}
            By becoming a distributor for Shyam-G Snacks, you'll gain access to
            various snack items that customers across India love. Tap into the
            immense popularity of Shyam-G Snacks and be part of a successful
            venture.{" "}
          </p>
          <p className="text-justify fs-5">
            This is the perfect chance if you're looking for a rewarding
            business opportunity and share our passion for delivering top-notch
            snacks. Join us in spreading the goodness of Shyam-G Snacks to every
            corner of India. Let's grow together!
          </p>
          <div>
            <Link to="https://info.shyamg.in/distributor">
              {" "}
              <button className="m-auto apply-btn blink">
              Click Here For Dealership
              </button>{" "}
            </Link>
          </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Distributor;
