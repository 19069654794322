import React, { useRef } from "react";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatc, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { addIndustrialVisit } from "../Reducers/CommonReducers"; 

const schema = yup.object().shape({
name: yup.string().required(),
phone: yup.string().min(10).max(10).required(),
email: yup.string().required(),
pinCode: yup.string().required(),
city: yup.string().required(),
state: yup.string().required(),
number: yup.string().required(),
  address: yup.string().required(), 
  standerd:yup.string().required(),
});

const IndustrialVisit = () => { 
  const dispatch = useDispatch(); 
  const width = useRef(window.innerWidth);
  return (
    <> 
      <div className={`bg-white ${width.current > 500 ? `p-5` : `p-2`}`}>
        <Row className="text-center b"> 
        <b>  <h2>
                 Industrial Visit
                  </h2> </b>
                 
                </Row>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                      dispatch(addIndustrialVisit(values)) 
                      resetForm({ values: "" });
            }} 
                    initialValues={{
                      name: "",
                      phone: "",
                      email: "",
                      state: "",
                      city: "",
                      pinCode:'',
                      number: "",
                      address: "", 
                      standerd:""
                    } 
                  
                    }
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Industrial /School Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={3} sm={12} className="mb-3">
                          <Form.Group >
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                type="text"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                isInvalid={!!errors.state}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.state}
                              </Form.Control.Feedback>
                            </Form.Group>
                  </Col> 
                  <Col md={3} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={!!errors.city}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.city}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                        <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>
                                Address
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                isInvalid={!!errors.address}
                                as="textarea"
                                rows={3}
                                style={{ height: "125px" }}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.address}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                  <Col md={6} sm={12} className="mb-3"> 
                    <Row> 
                    <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Pin Code</Form.Label>
                              <Form.Control
                                type="number"
                                name="pinCode"
                                value={values.pinCode}
                                onChange={handleChange}
                                isInvalid={!!errors.pinCode}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.pinCode}
                              </Form.Control.Feedback>
                            </Form.Group>
                      </Col> 
                       
                      <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>
                                Mobile Number
                              </Form.Label>
                              <Form.Control
                                type="number"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.phone}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                    </Row>  
                    <Row> 
                    <Col md={12} sm={12}>
                    <Form.Group >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                        </Form.Group>  
                        </Col>
                      </Row>  
                          </Col>
                        </Row>
                       
                        <Row>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Number Of Student</Form.Label>
                              <Form.Control
                                type="text"
                                name="number"
                                value={values.number}
                                onChange={handleChange}
                                isInvalid={!!errors.number}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.number}
                              </Form.Control.Feedback>
                            </Form.Group>
                  </Col> 
                  <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Standard</Form.Label>
                              <Form.Control
                                type="number"
                                name="standerd"
                                value={values.standerd}
                                onChange={handleChange}
                                isInvalid={!!errors.standerd}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.standerd}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          </Row>
                        <Row className="mb-3">
                        
                        </Row>
                        <Button type="submit" className="cards-btn">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
    </>
  );
};

export default IndustrialVisit;
