import React from "react";
import chipsbule from "../Images/products/waahjibluechips.png";
import Accordion from "react-bootstrap/Accordion";

const ProductsDetails = () => {
  return (
    <>
      <div className="contact-us-bg">
        <div className="container text-center text-white pt-5 pb-5">
          <h2 className="fs-1 text-center">Products detail</h2>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4">
            <img src={chipsbule} alt="" />
          </div>

          <div className="col-md-8">
            <h2>
              <b>Fun Leo Cocktail</b>
            </h2>
            <div>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
            </div>
            <div className="product-rate">
              <h2> 5/-</h2>
            </div>
            <div className="mt-3">
              <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Discription</Accordion.Header>
                  <Accordion.Body>
                    Fun Leo is the new product of ShyamG Snacks Comes in
                    different flavors. These snacks are full of carbohydrates
                    and protein, and they are a very good source of Energy. The
                    use of Lemon in the Fun Leo Cocktail makes it tastier. These
                    types of snakes are made for people of every age group. Try
                    the latest product of ShyamG Snacks.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Ingredients</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Advantages</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>100% VEGETARIAN:</li>
                      <li>100% VEGETARIAN:</li>
                      <li>Hygienic</li>
                      <li>Less Perishable</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="mt-3">
              <button className="btn border"> Shop Now </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsDetails;
