import React from "react";
import about from "../Images/about.jpg";  

const About = () => {
  return (
    <>
      <div className="">
        <div className="row fixed-height-all-slider p-0 m-0">
          <div className="col-md-6 p-0 corner-sets">
            <img className="custom-img" src={about} />
          </div>
          <div className="col-md-6 desktop-none mt-4">
            <iframe
              src="https://www.youtube.com/embed/13KIzMrMzyA"
              width="100%"
              height={400}
              title="Iframe Example"
            ></iframe>
          </div>
          <div className="col-md-6 corner-sets p-0 round-card-content">
            <div className="round-card w-100 h-100">
              <div className="cards-content text-justify">
                <h3>Shyam-G Snacks: Nurturing Communities, Crafting Taste</h3>
                <p>
                  {" "}
                  Discover the heart and soul of Shyam-G Snacks, where we craft
                  delicious snacks, nurture our communities, care for our
                  environment, and empower our workforce.
                </p>
                <p>
                  In 2007, Mr. Amit Singal decided to walk his path and create a
                  new identity for himself, this led to the birth of the brand
                  Shyam-G. On 12th November 2007, He Started with the 11
                  thousand sq feet setup and launched Fried and Extruded snacks
                  under the name Shyam-G Snacks. In 2021 they started a
                  Manufacturing setup of 36 thousand Sq feet in Kanpur (Uttar
                  Pradesh) also.<br/>
                  At a time when the technology to produce Products on a large
                  scale was unthought of, Mr. Singal successfully laid the
                  foundation of his dream venture and started production on the
                  mass level, and designed an 4 lakh 80 thousand Sq feet set-up
                  in early 2022 at Jagan (Hisar), and launched new products with
                  multi flavors in different categories. In the initial phase
                  Shyam-G Snacks deals only in Haryana, But today they are
                  present in most of the locations in North India and planning
                  to make it available for Pan India.
                </p>
                <p>
                  Welcome to <b>Shyam-G Snacks</b>, a leading manufacturer of
                  healthy and tasty snacks in North India. Our journey began
                  with a vision to create healthy snack options for people that
                  are not only tasty but also good for their well-being.<br/>
                  Shyam-G Snacks is a renowned name in the field of snack items.
                  Shree Shyam-G Snacks have a popular name in every household
                  across India. From kids to old age people all know the quality
                  and the taste of Shyam G snacks and want to have these snacks.
                  People buy Shyam G snack packets for their trips too.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 mb-5 customer-mrgn">
          <div className="col-md-6 mt-4">
            <h2>
              <b>Our History</b>
            </h2>
            <hr />
            <p className="custome-style text-justify">
              To provide a variety of the best snacks to our customers we use
              the latest technology. We are constantly engaged in manufacturing
              and supplying assortments such as Mast Mast, Pasta, Wifi Cones,
              Samosa Mixture, Natoore, Fun Polo, and much more. Our production
              units have been designed to meet all international standards
              within the food industry. We use the best quality raw material to
              manufacture all our products according to the user’s taste.
            </p>
          </div>
          <div className="col-md-6 mbl-none">
            <iframe
              src="https://www.youtube.com/embed/13KIzMrMzyA" // Correct URL format for embedding YouTube videos
              width="100%"
              height={400}
              title="Iframe Example"
            ></iframe>
          </div>
        </div>
      </div>
      {/* <Container>
        <Row>
        <h2>
              <b>Our Certificates</b>
            </h2>
            <hr />
        </Row>
        <Row>
          <Col md={3} className="mb-2">
            <img className="w-100" src='https://www.edrawsoft.com/images/certificate/certificate-template-portrait1.png'/>
          </Col>
          <Col md={3} className="mb-2">
            <img className="w-100" src='https://www.edrawsoft.com/images/certificate/certificate-template-portrait1.png'/>
          </Col>
          <Col md={3} className="mb-2">
            <img className="w-100" src='https://www.edrawsoft.com/images/certificate/certificate-template-portrait1.png'/>
          </Col>
          <Col md={3} className="mb-2">
            <img className="w-100" src='https://www.edrawsoft.com/images/certificate/certificate-template-portrait1.png'/>
          </Col>
        </Row>
      </Container> */}
      <div className="misson-section">
        <div className="container">
          <div className="row mission-row">
            <div className="col-md-6 customer-mrgn">
              <h2> OUR MISSION</h2>
              <p>
                As a team, our mission is to provide the variety of best snacks
                to our customers and to drive higher goodwill in the market. Our
                company uses the latest technology to provide freshness and
                quality in our products.
              </p>
            </div>
            <div className="col-md-6 customer-mrgn">
              <h2> Our Vision</h2>
              <p>
                To become a market leader in the snacks manufacturing business
                by providing quality, healthy and tasty packed snacks to our
                customers at a reasonable price.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row text-center number-client">
          <div className="col-md-4">
            <ul className="list-unstyled">
              <li className="number"> 34+</li>
              <li className="fs-5">Products</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="list-unstyled">
              <li className=" number">50 Million+</li>
              <li className="fs-5">Customers</li>
            </ul>
          </div>

          <div className="col-md-4">
            <ul className="list-unstyled">
              <li className=" number">Pan India</li>
              <li className="fs-5">Shyam-G Coverage</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
