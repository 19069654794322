import React from "react";
import PastaMasala from "../../Images/products/new-shymG-produdts/6.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const PastaMasalaMunch = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Pasta Masala Munch
          "
        productname="Pasta Masala Munch
          "
        productrate="5/-"
        productdiscription="The yummy taste of pasta now comes in the form of crunchy snacks. Introducing mouth-watering ShyamG Pasta Masala Munch Crunchy Snacks – your new snack-time partner.

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home.

        "
        productingredients="Spices and Condiments (Chili Powder, Coriander, Turmeric Powder, Ginger Powder, Cumin, Awon Clove, Cinnamon Cardamom, Nutmeg Pepper, Oregano, Thyme, Mustard, Maltodextrin, Dried Mango Powder, Garlic Powder. Onion Powder. Acidity Regulator (INS 330) and Flavor Enhancer (NS 631) Natural & Nature Identical Flavoring Substance.), Sugar iodised Salt,
        Potato Flakes,
        Edible Vegetable Oil (Palmolein Oil, Cotton Seed Oil) Tapioca Starch
        Refined Wheat Flour (Maida)
        "
        productimg={PastaMasala}
      />
    </>
  );
};

export default PastaMasalaMunch;
