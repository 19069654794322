import React, { useRef, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import waahji from "../Images/waahji.png";
import call from "../Images/call.png";
import Fade from "react-bootstrap/Fade";

const Footer = ({ mrgnTop }) => {
  const [open, setOpen] = useState(false);
  const width = useRef(window.innerWidth);
  return (
    <>
      {/* <div className="wcall-button">
        <div className="phone-and-whatsapp">
          {" "}
          <Fade in={open}>
            <ul className="list-group mb-4">
              <li className="list-group-item mb-3">
                {" "} <Link to="tel:+91 99929-24365"> 
                <i className="bi bi-telephone call-btn"> </i>{" "} </Link>
              </li>
              <li className="list-group-item">
                {" "} <Link to="https://api.whatsapp.com/send?phone=+919992924365&text=Hello Shyam-G"> 
                <i className="bi bi-whatsapp what-icon"> </i>{" "} </Link>
              </li>
            </ul>
          </Fade>{" "}
        </div> 

        <div className="mt-3 bottom-button message-icon">  
          <Link className="text-white"> 
        <i
          className="bi bi-envelope whatsapp-call"
          onClick={() => setOpen(!open)}
          aria-controls="example-fade-text"
          aria-expanded={open}
        ></i> </Link>
        </div>
        
      </div> */}
      <div className={mrgnTop ? mrgnTop : "footer-section"}>
        <div className="footer-section-2">
          <div className="container">
            <div className="row d-flex footer-row">
              <Col md={3} sm={4} xs={4} className="px-1">
                <h2 className="foot-heading"> QUICK LINKS </h2>
                <ul className="list-unstyled">
                  <li>
                    <Link className="" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/our-process">
                      Our Process
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/csr">
                      CSR Activities
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/contact-us">
                      Offices
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/industrial-visit">
                      Industrial Visit
                    </Link>
                  </li>
                  {/* <li> 
                    <Button className="cards-btn call-button"> 
                    <Link className="text-white"  to="tel:+91 99929-24365">
                    Call
                      </Link> 
                      </Button>
                  </li> */}
                </ul>
              </Col>
              <Col md={3} sm={4} xs={4} className="px-1">
                <h2 className="foot-heading"> OUR SERVICES </h2>
                <ul className="list-unstyled">
                  <li>
                    <Link className="" to="/products">
                      Products
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/career">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/distributor">
                    Dealership
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={4} xs={4} className="px-1">
                <h2 className="foot-heading"> Gallery </h2>
                <ul className="list-unstyled">
                  <li>
                    <Link className="" to="/events">
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link className="" to="/corporate-video">
                      Corporate Infrastructure
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="" to="/gallery">
                      Photos
                    </Link>
                  </li> */}
                </ul>
              </Col>
              <Col md={3} sm={12} xs={12} className="px-1">
                <h2 className="foot-heading"> REACH US </h2>
                <ul className="list-unstyled">
                  <li>
                    <b>Email</b>:{" "}
                    <Link className="" to="/about-us">
                      info@shyamg.in
                    </Link>
                  </li>
                  <li>
                    <b>Phone</b>:{" "}
                    <Link className="" to="tel:+91 99929-24365">
                      +91 99929-24365
                    </Link>
                  </li>
                  <li>
                    <b>TollFree Number</b>:{" "}
                    <Link className="" to="tel:18001200490">
                      18001200490
                    </Link>
                  </li>
                  <li>
                    <b>Customer Care Timings</b>:{" "}
                    <Link className="" to="/about-us">
                      10:00 AM To 6:00 PM
                    </Link>
                  </li>
                  <li>
                    <b>Customer Care Days</b>:{" "}
                    <Link className="" to="/about-us">
                      Monday to Saturday
                    </Link>
                  </li>
                  {/* <li> 
                    <Button className="cards-btn call-button "> 
                    <Link className="text-white whatsapp-btn"  to="https://api.whatsapp.com/send?phone=+91 99929-24365&text=Hello Shyam-G">
                    Whatsapp
                      </Link> 
                      </Button>
                  </li> */}
                </ul>
              </Col>
            </div>
          </div>
          <Container fluid>
            <hr className="mb-0" />
            <div className="d-flex row justify-content-between">
              <Col
                md={6}
                className={`mt-1 ${width.current < 500 && "text-center"}`}
              >
                <a href="#">
                  <img className="responsive-logo-h" src={waahji} />
                </a>{" "}
                a new brand of <b>Shyam-G Snacks.</b>
              </Col>
              <Col md={6} className="footer-link mt-3">
                <p className={width.current < 500 ? "text-center" : "text-end"}>
                  Copyright © {new Date().getFullYear()} Shyam-G. All Rights
                  Reserved. Created with ♥ by{" "}
                  <a className="p-0" href="http://havfly.com/" target="_blank">
                    <b>Havfly</b>
                  </a>
                </p>
              </Col>
            </div>
          </Container>
        </div>
      </div>
      {/* ----------------------floating icon----------------------- */}
      <div className="icon-bar">
        <a
          target="_blank"
          href="https://www.facebook.com/shyamgsnacks"
          className="facebook"
        >
          <i className="bi bi-facebook"></i>
        </a>
        <a
          target="_blank"
          href="https://twitter.com/shyamgsnacks"
          className="twitter"
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-twitter-x"
            viewBox="0 0 16 16"
          >
            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/shyamgsnacks"
          className="google"
        >
          <i className="bi bi-instagram"></i>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/shyamgsnacks/"
          className="linkedin"
        >
          <i className="bi bi-linkedin"></i>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UC1PFwtNLOw2vBim11ukHcOw/videos"
          className="youtube"
        >
          <i className="bi bi-youtube"></i>
        </a>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=+919992924365&text=Hello-Shyam-G" className="whatsapp"><i className="bi bi-whatsapp"></i></a>   
       
      </div>
      {width.current < 500 && <a href="tel:+919992924365" className="mbl-call-icon"><img src={call} alt="call" title="call"/></a>}   
    </>
  );
};

export default Footer;
