import React from "react";
import { Container, Row } from "react-bootstrap";
import csr from "../Images/banner/crs.gif";
import crm from "../Images/crm.png";
import company from "../Images/csr/shyamgcsr.jpg";
import solarplant from "../Images/powerplant.jpg";
import solarenergy from "../Images/csr/solarplant.jpg";
import treeplanting from "../Images/crop-hands-saving-sprout.jpg";
const CSR = () => {
  return (
    <>
      <div className="">
        <div className="row fixed-height-all-slider p-0 m-0">
          <img className="p-0 corner-sets" src={csr} />
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 mb-5 customer-mrgn align-item-center">
          <div className="col-md-6 mt-4">
            <h2>
              <b>
                Corporate Social Responsibility: Crafting A Sustainable Tomorrow
              </b>
            </h2>
            <hr />
            <p className="custome-style text-justify">
              At Shyam-G Snacks, we believe in the power of giving back. For us,
              corporate social responsibility isn't just a buzzword; it's a way
              of life. We are dedicated to extending our business beyond
              profits, creating a sustainable ecosystem where everyone thrives.
              We seek to create shared value for consumers, stakeholders, and
              communities through transparent collaborations with our
              associates. Our commitment to social welfare ripples through our
              surroundings, positively impacting all we touch.
            </p>
          </div>
          <div className="col-md-6 image-animat-container">
            <img className="w-100" src={crm} />
          </div>
        </div>
      </div>
      <div className="misson-section">
        <div className="container">
          <div className="row mission-row">
            <div className="col-md-12 pt-5 pb-5 customer-mrgn">
              <h2>Our Workforce: Nurturing Growth, Fostering Family</h2>
              <p className="text-justify">
                In the heart of Shyam-G Snacks, you'll find a business and a
                family. Our primary objective is to create a work environment
                where our employees flourish. We believe a happy and motivated
                workforce is our most valuable asset. Through regular training
                programs, we ensure effective teamwork and personal development.
                Safety is our top priority, extending to our employees and the
                food they prepare. We diligently train our teams to maintain
                impeccable quality standards while safeguarding against injury,
                accidents, and health hazards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Row className="mt-4 coloum-reverse">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              The Community: Empowering Lives, Enriching Futures
            </h3>
            <p className="text-justify">
              Our commitment to the community extends beyond our snacks. We
              actively collaborate with farmers, supporting them in developing
              sustainable agricultural practices. By sourcing raw ingredients
              from these farmers, we ensure the highest quality in our product
              standards and uplift their livelihoods. We take pride in
              sponsoring programs that promote education at early learning
              stages through partnerships with promising NGOs across India.
            </p>
            <p className="text-justify">
              Through various activities, from awareness campaigns to
              fundraising, volunteering, and donations, we champion initiatives
              close to our hearts. We actively participate in nationwide
              collective efforts like the Swacch Bharat Abhiyan and launch
              in-house campaigns to enhance the quality of life for
              underprivileged communities. At Shyam-G Snacks, we believe that by
              nurturing our communities, environment, and workforce, we are
              crafting a taste that truly matters.
            </p>
            <p className="text-justify">
              We have actively participated in the nationwide collective effort
              known as Swacch Bharat Abhiyan and promoted our in-house campaigns
              aimed at enhancing the quality of life for underprivileged
              communities.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={company} />
          </div>
        </Row>
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={solarenergy} />
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Embracing Solar Energy for a Sustainable Future
            </h3>
            <p className="text-justify">
              At our organization, sustainability and responsible energy
              consumption are at the forefront of our operational philosophy. We
              are proud to announce that we have taken a significant step
              towards a greener and more environmentally conscious future by
              integrating solar energy into our plans. By harnessing the power
              of the sun, we are not only reducing our carbon footprint but also
              contributing to a cleaner and more sustainable energy ecosystem.
              This transition to solar energy is a testament to our commitment
              to a brighter and more sustainable tomorrow, and we are excited to
              lead by example in promoting renewable energy solutions.
            </p>
            <p className="text-justify">
              Solar energy, often referred to as the "energy of the future," is
              a renewable and abundant source of power that holds the key to a
              more sustainable world. It relies on harnessing the energy emitted
              by the sun in the form of sunlight and converting it into
              electricity or heat. Solar panels, also known as photovoltaic (PV)
              cells, are the primary technology used to capture solar energy.
              These panels contain semiconductors that absorb photons from the
              sun's rays, generating a flow of electrons that, in turn, produces
              electrical current. One of the remarkable aspects of solar energy
              is its versatility; it can be harnessed on various scales, from
              small residential rooftop installations to vast solar farms that
              can power entire communities.
            </p>
          </div>
        </Row>
        <Row className="mt-4 coloum-reverse">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Transforming Waste into Wellness: Shyam-G's STP Plants
            </h3>
            <p className="text-justify">
              Shyam-G Snacks is a pioneer in crafting delectable treats and a
              sustainability champion. Their state-of-the-art Sewage Treatment
              Plants (STP) showcase a commitment to environmental
              responsibility. These STP plants are a testament to their
              dedication to transforming waste into a valuable resource. Shyam-G
              adheres to stringent environmental regulations and contributes to
              cleaner communities by efficiently treating wastewater. Beyond
              crafting flavors, they craft a cleaner, greener future. Shyam-G
              Snacks, where sustainability meets taste, one snack at a time.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={solarplant} />
          </div>
        </Row>
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={treeplanting} />
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Nurturing Nature: Our Commitment to Greenery Through Tree Planting
            </h3>
            <p className="text-justify">
              Preserving the natural environment and contributing to a healthier
              planet are fundamental values at our organization. To actively
              promote a sustainable and green workspace, we have embarked on an
              initiative to plant trees within our facility. This endeavor not
              only enhances the aesthetics of our premises but also aligns with
              our commitment to environmental responsibility.
            </p>
            <p className="text-justify">
              Our tree planting efforts serve multiple purposes. Firstly, they
              create a lush and inviting atmosphere for our employees and
              visitors, fostering a more pleasant and productive work
              environment. The presence of greenery has been shown to reduce
              stress, boost creativity, and improve overall well-being.
              Furthermore, trees act as natural air purifiers, absorbing carbon
              dioxide and releasing oxygen, thereby enhancing the air quality
              within our workspace.
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CSR;
