import React from "react";
import funcure from "../../Images/products/new-shymG-produdts/12.png";
import ProductsDetails from "../../Component/SingleProductDetail";

const Frenchobreak = () => {
  return (
    <>
      <ProductsDetails
        productheading="French ‘o’ Bake"
        productname="French ‘o’ Bake"
        productrate="5/-"
        productdiscription="ShyamG French O Bake are sensational to taste-they are crispy on the outside and fluffy on the inside and have a great taste that lingers on your palate throughout!"
        productingredients="Rice Meal (33.2%), Corn Meal (33.2%), Edible Vegetable Oil (Palmolen And Cottonseed Oil) Sugar," 
        productimg={funcure}
      />
    </>
  );
};

export default Frenchobreak;
