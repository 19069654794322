import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import chips from "../../Images/new waah ji/Chips Blue Packet.png";

const Waahjichips = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="waahji-chips"
        productname="waahji-chips"
        productrate="5/-"
        productdiscription="WAAHJI Chips are offering our clients a very crunchy & tasty chips. It contains potato, edible vegetable oil, sugar powder, tomato powder, edible common salt, spices & condiments. It is available in multiple flavors like Tangy Tomato, Cream ‘n’ Onion, Classic Salted, and Magic Masala Flavour."
        productingredients="Rice Meal(43.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Corn Meal(17%), Gram Flour(4%) Refined Sugar Powder, Iodised Salt, Spice & Condiments(Tomato Powder, Chili Powder, Cumin Powder, Cinnamon Powder ,Dried Mango Powder, Garlic Powder, Onion Powder), Maltodextrin, Acidity Regulator (INS 330), Flavour Enhancers (INS 630, INS 631) Natural & Nature identical Flavoring Substances, Asafoetida and Anticaking agent (INS551)"
        productimg={chips}
      />
    </>
  );
};

export default Waahjichips;
