import React, { useRef } from "react";
import SpringSlider from "../Sub/SpringSlider";
import leftarrowicon from "../../src/Images/icons/right-arrow.png";
import ProductsSlider from "../Component/ProductsSlider";
import Testimonial from "../Component/Testimonial";
import { Link } from "react-router-dom";
import productsSide from "../Images/shyamg-product.png";
import NewsLetter from "../Component/NewsLetter";
import { Container } from "react-bootstrap";

const Homepage = () => {
  const width = useRef(window.innerWidth);
  return (
    <>
      <SpringSlider />
      <div className={`products-section ${width.current < 500 ? `mt-3` : ""}`}>
        <div className="about-section">
          <div className=" container text-center">
            <div className="about-uper">
              <h1 className="about-heading">Shyam-G Snacks</h1>
              <h3>The Best Snack Manufacturing in India</h3>
              <p className="about-paragraph">
                Shyam-G Snacks has emerged as a shining star in the vibrant and
                diverse world of snacks. Renowned as a leading snacks
                manufacturer in India, Shree Shyam Snacks Food Private Limited,
                known as Shyam-G Snacks, has carved a niche through its
                commitment to quality and a wide array of delectable snack
                offerings. We have established a strong reputation in the
                market. Shree Shyam-G Snacks used high-quality raw materials for
                all its food products. Healthy & hygienic products, a Wide range
                of variety, and high-quality products are the key reasons behind
                Shree Shyam-G Snacks popularity. Shyam-G provides the supply of
                their foods all over India.
              </p>
              
              <Link to="/about-us">
                <button className="btn border btn-padding slider-btn">
                  Read More{" "}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container">
            <h2 className="text-center products-section-heading">
              A Glimpse into Shyam-G Snacks' Offerings
            </h2>

            {/* <h3 className="about-subheading text-center">
              Explore our various product range of authentic and delicious
              products.
            </h3> */}
            <div className="products-slider chips">
              <div className="container">
                <ProductsSlider />
              </div>
            </div>

            <Container>
            <ul>
              <li>
                {" "}
                Pasta Masala Munch: A delightful twist to traditional pasta,
                every bite of Pasta Masala Munch is a fusion of crispiness and
                crunchiness with a burst of masala flavor. It's a taste that has
                won hearts all over India.
              </li>
              <li>
                {" "}
                Chopcy, Wi-Fi Cone, Fun Polo, and Natoore: We don't stop at
                Pasta Masala Munch; they offer many other options. From the
                unique flavors of Chopcy and Wi-Fi Cone to timeless classics
                like Fun Polo and Natoore, there's something for everyone.
              </li>
              <li>
                {" "}
                Perfect Anytime Snacks: Good snacks should be enjoyed anytime,
                anywhere. Whether it's an evening tea, a quick bite during work,
                or a midnight craving, their snacks fit every occasion
                perfectly.
              </li>
            </ul>
            </Container>
          </div>
        </div>
      </div>
      <div className="round-card-section">
        <div className="header-repted-img transform"></div>
        <div className="row p-0 m-0 align-item-center yellow-bg">
          <div className={`col-md-6 p-0 text-center ${width.current < 500 ? `pt-3` : ""}`}>
            <img src={productsSide} className="custom-img" />
          </div>
          <div className="col-md-6 p-0 round-card-content ">
            <div className="w-100 round-card">
              <div className="cards-content">
                <h2 className="mb-2">Why Choose Shyam-G Snacks?</h2>
                <hr />
                <ul>
                  <li>
                    {" "}
                    Healthy and Hygienic: Shyam-G Snacks are synonymous with
                    quality. With a firm belief that quality begins with the
                    right ingredients, they source only the finest raw materials
                    to craft their snacks. The emphasis on hygiene runs deep in
                    their manufacturing process, ensuring every snack that
                    reaches you is tasty and safe.
                  </li>
                  <li>
                    {" "}
                    Variety That Delights: One size doesn't fit all, especially
                    regarding snacking. We understand this well, offering
                    diverse snacks to cater to every palate. Whether you're
                    craving the fiery zest of Pasta Masala Munch, the crispy
                    indulgence of Mast Mast, or the savory satisfaction of
                    Samosa Mixture, Shyam-G Snacks has you covered.
                  </li>
                  <li>
                    Nationwide Presence: We have etched our presence across
                    India, delivering lip-smacking snacks to every corner of the
                    nation. Their snacks are a beloved companion to countless
                    cups of tea, elevating snack time to a delightful
                    experience.
                  </li>
                </ul>
                <Link to="/products">
                  <button className="btn border cards-btn">
                    <span className="icon-img">
                      View All <img src={leftarrowicon} alt="" />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="header-repted-img"></div>
      </div>

      <div className="gradients">
        <div className="container">
          <div className="row">
            <h2 className="text-center">
              {" "}
              What Makes Shyam-G Snacks the Best Snack Manufacturers in India?{" "}
            </h2>
            <p className="text-center">
              {" "}
              We aren’t just a snack manufacturer; it's a promise of quality,
              variety, and taste. When you choose Shyam-G Snacks, you choose
              snacks crafted with care and packed with flavor. With a commitment
              to providing quality at an affordable price, these snacks are
              light on your pockets and heavy on taste.
            </p>
            <p className="text-center">
              Shyam-G Snacks stands out as a manufacturer that delivers snacks
              and delights, one bite at a time. So, the next time you crave a
              crispy and mouth-watering snack, remember, Shyam-G Snacks has got
              you covered, no matter where you are in India.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4 g-3  col-12">
              <div className="shapes  w-100 shadow">
                <h3>Mood-Based Flavors</h3>
                <p>
                  Shyam-G Snacks understands that snacking often depends on your
                  mood. That's why they offer a wide range of spicy and
                  non-spicy snacks, ensuring there's something to satisfy every
                  craving.
                </p>
              </div>
            </div>
            <div className="col-md-4 g-3 col-12 bg-shadow">
              <div className="shapes  w-100  shadow">
                <h3> Quality Products</h3>
                <p>
                  We're all about crafting snack experiences that are as
                  delightful as they are trustworthy. Our commitment to quality
                  starts from the finest ingredients that every bite of our
                  snacks is a testament to excellence.
                </p>
              </div>
            </div>
            <div className="col-md-4 g-3 col-12 ">
              <div className="shapes  w-100 shadow">
                <h3>Affordable Delights</h3>
                <p>
                  At Shyam-G Snacks, we believe great snacking should be
                  accessible to all. That's why we've made it our mission to
                  offer high-quality and budget-friendly snacks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testmoinal-slider">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      <NewsLetter />
    </>
  );
};

export default Homepage;
