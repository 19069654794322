import React from "react";
import imgone from "../Images/Gallery/processbanner.jpg";
import imgtwo from "../Images/Gallery/UOD_4139-scaled.jpg";
import imgthree from "../Images/Gallery/UOD_4254-scaled.jpg";
import four from "../Images/Gallery/UOD_4268-scaled.jpg";
import five from "../Images/Gallery/UOD_4295-scaled.jpg";
import six from "../Images/Gallery/UOD_4297-scaled.jpg";
import seven from "../Images/Gallery/UOD_4303-scaled.jpg";
import eight from "../Images/Gallery/UOD_4349-scaled.jpg";
import nine from "../Images/Gallery/UOD_4381-scaled.jpg";
import ten from "../Images/Gallery/UOD_4406-scaled.jpg";
import eleven from "../Images/Gallery/UOD_4408-scaled.jpg";

const Gallery = () => {
  return (
    <>
      <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Gallery</h2>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <img
              src={imgone}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
            <img
              src={imgtwo}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Wintry Mountain Landscape"
            />
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              src={imgthree}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Mountains in the Clouds"
            />
            <img
              src={four}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              src={five}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />
            <img
              src={imgthree}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
          </div>

          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              src={six}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />
            <img
              src={seven}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
          </div>

          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              src={nine}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />
            <img
              src={eight}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <img
              src={ten}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Waves at Sea"
            />
            <img
              src={eleven}
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Yosemite National Park"
            />
          </div>
        </div>
      </div>
      {/* Gallery */}

      {/* Gallery */}
    </>
  );
};

export default Gallery;
