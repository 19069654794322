import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import wificone from "../../Images/products/new-shymG-produdts/1.png";
const WifiCone = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="WiFi Cone Crunchy “n” Crispy
             "
        productname="WiFi Cone Crunchy “n” Crispy
             "
        productrate="5/-"
        productdiscription="Wifi Cone is a unique shaped snack with the same crispiness and crunch. It is in the Empty shape of a cone. To enhance your taste we put one tomato ketchup pouch in the pack, fill the cone with ketchup and enjoy the never eaten flavor before with ShyamG Snacks.

             "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Wheat Flour (Maida),Rice Flour,
        Edible Vegetable Oil (Palmolein Oil or Cotton Seed Oil or Rice Bran Oil),
        Tapioca Starch,
        Maize,
        Sugar,
        Iodised Salt,
        Spices & Condiments (Chilly Powder, Fennel, Clove, Cinnamon Powder, Dried Mango Powder, Garlic Powder, Onion Powder, Tomato Powder) Acidity Regulator (INS 330, INS 500ii),
        Flavour Enhancer (INS 627, 631) Natural & Nature Identical Flavoring Substances,
        Anti-caking Agent (INS 551)
        "
        productimg={wificone}
      />
    </>
  );
};

export default WifiCone;
