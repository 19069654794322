import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import mastsnax from "../../Images/new waah ji/Waahji Mast Snax.png";

const MastSnax = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Mast Snax"
        productname="Mast Snax"
        productrate="5/-"
        productdiscription="WAAHJI Mast Snax (Pasta) is pellet type snack specially developed for your taste. It consists of wheat flour and edible starch, deep fried in fresh oil. All ingredients are fresh and natural. This Mast Snax is different from the other brands pasta products. Our unique special recipe is the base of our unique taste and texture. We have developed the seasoning taste specially for your taste."
        productingredients="Rice Meal(43.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Corn Meal(17%), Gram Flour(4%) Refined Sugar Powder, Iodised Salt, Spice & Condiments(Tomato Powder, Chili Powder, Cumin Powder, Cinnamon Powder ,Dried Mango Powder, Garlic Powder, Onion Powder), Maltodextrin, Acidity Regulator (INS 330), Flavour Enhancers (INS 630, INS 631) Natural & Nature identical Flavoring Substances, Asafoetida and Anticaking agent (INS551)"
        productimg={mastsnax}
      />
    </>
  );
};

export default MastSnax;
