import React from 'react'; 
import Tomatochips from "../../Images/products/new-shymG-produdts/8.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const MasalaNoodles = () => {
  return (
    <>
       <SingleProductsDetails
        productheading="Masala-noodles"
        productname="Masala-noodles"
        productrate="5/-"
        productdiscription="These sweet and salty noodles flavor chips with the irresistible flavor of noodles take you to the next level of taste. Masala-noodles with mouth-watering taste of traditional noodles flavor

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home.

        "
        productingredients="Refined  Wheat Flour 48% (Maida), Edilble Vegetables Oil (Palmolien Oil or Cotton Seeds Oil or Rice Bran Oil, Tapicoa Starch, Sugar, Ionised Salt Spice conditment (Chilli powder, Coradian turmrice, Powder, Cumin Ajmin,Clove, Cinnamon, Nutemeg,Pepper, Orengo,Thyme), Maltoxide, Dried Mango,Powder,Oregano.Acidity Regulator(INS 330 ) and  Flavour  Enchacer (INS 631) Natural & Natural Identical  Flavouring  Substances. "
        productimg={Tomatochips}
        />
        </>
  );
}

export default MasalaNoodles;
