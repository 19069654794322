import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import saltedchips from "../../Images/products/new-shymG-produdts/15.png"

const Saltedchips = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Salted Chips
                 "
        productname="Salted Chips
        "
        productrate="5/-, 10/-
        "
        productdiscription="ShyamG Classic Salted Chips are made from the best potatoes and seasoned with just the right amount of salt. So every Classic salted chips is perfectly crispy and full of fresh potato taste. Happiness in Every Bite.

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients=" Edible Vegetable Oil(Palmolein oil, Cottonseed Oil), and Edible Common Salt(1%).Potato(89%),

        "
        productimg={saltedchips}
      />
    </>
  );
};

export default Saltedchips;
