import React from "react";
import { Col } from "react-bootstrap";
import { waahjiProductsArray } from "../const";
import Product from "../../Component/Product";
const Upcomming = () => {
  return (
    <>
      <div className="products-category products-section">
        <div className="container">
          <h2 className="text-center fs-1">Upcoming Products</h2>
          <div className="border-design  text-center mb-2"> </div>
          <div className="row">
            {waahjiProductsArray.map((data, index) => { 
              return (
                <Col md={4} key={index}>
                  <Product
                    productsimg={data?.img}
                    productsname={data?.name}
                    // productsprice={data?.productsprice}
                    url={data?.url}
                  />
                </Col>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Upcomming;
