import React from "react";
import funcuretangi from "../../Images/products/09.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const FunLeoChineaseMasala = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="FUN LEO CHINESE Masala
           "
        productname="FUN LEO CHINESE Masala
           "
        productrate="5/-"
        productdiscription=" Fun Leo is the new product of ShyamG Snacks Comes in different flavors. These snacks are full of carbohydrates and protein, and they are a very good source of Energy. The use of onion in Fun Leo makes it tastier. These types of snakes are made for people of every age group. Try the latest product of ShyamG Snacks.

           "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(65.3%), Corn Meal(7.2%),Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Wheat Flour, Edible Common Salt, Refined Sugar Powder,
        Spice & Condiments(Chilly Powder, Coriander Powder, Cumin Powder, Ginger Powder, Turmeric Powder, Fenugreek Powder, Black pepper, Ajwain, Nutmeg Powder, Fennel, Clove Powder, Cinnamon Powder,
          Aniseed Powder, Cardamom Powder, Garlic Powder, Methi Leaf, Onion Powder) Flavour Enhancer (INS 627, INS 631) Natural & Nature identical Flavoring Substances,
          Acidity Regulator (INS 330), Anticaking agent (INS551), Contains added- Permitted Natural Color (INS 100(ii))

        "
        productimg={funcuretangi}
      />
    </>
  );
};

export default FunLeoChineaseMasala;
