import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import fronchobreak from "../../Images/products/new-shymG-produdts/9.png";

const FUNCURETANGYTOMATO = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="FunCure Tangy Tomato"
        productname="FunCure Tangy Tomato"
        productrate="5/-"
        productdiscription="Crunchy solid sticks in two flavors – FunCure Masala Masti for spice lovers and tangy tomato for Kids. The Masala Masti is a classic flavor with a great combination of spice and crunch."
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(43.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Corn Meal(17%), Gram Flour(4%) Refined Sugar Powder, Iodised Salt, Spice & Condiments(Tomato Powder, Chili Powder, Cumin Powder, Cinnamon Powder ,Dried Mango Powder, Garlic Powder, Onion Powder), Maltodextrin, Acidity Regulator (INS 330), Flavour Enhancers (INS 630, INS 631) Natural & Nature identical Flavoring Substances, Asafoetida and Anticaking agent (INS551)"
        productimg={fronchobreak}
      />
    </>
  );
};

export default FUNCURETANGYTOMATO;
