import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import fronchobreak from "../../Images/products/new-shymG-produdts/10.png";

const Funcuremasalamsti = () => {
  return (
    <>
      {/* <div className="contact-us-bg">
        <div className="container text-center text-white pt-5 pb-5">
         
          <h2 className="fs-1 text-center">FunCure Masala Masti</h2>{" "}
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4">
            <img src={funcure} alt="" />
          </div>

          <div className="col-md-8">
            <h2>
              <b>FunCure Masala Masti</b>{" "}
            </h2>
            <div>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
            </div>
            <div className="product-rate">
              <h2> 5/-</h2>
            </div>
            <div className="mt-3">
              <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Discription</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Crunchy solid sticks in two flavors – FunCure Masala Masti
                      for spice lovers and tangy tomato for Kids. The Masala
                      Masti is a classic flavor with a great combination of
                      spice and crunch.
                    </p>
                    <p>
                      Snacks are small services that delight the hunger of your
                      stomach, generally the interval between meals. Shree
                      ShyamG Snacks provides a variety of packaged fried snacks
                      and other processed food, using fresh ingredients like
                      home.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Ingredients</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Rice Meal(43%, Edible vegetable oil (Palmolein oil or
                      Cottonseed Oil or Rice Bran Oil), Corn Meal(17%), Gram
                      Meal(4%) Refined Sugar Powder, Iodised Salt, Spice &
                      Condiments (Chili Powder, Coriander Powder, Ginger Powder,
                      Cumin Powder, Nutmeg, Papper, Cardamom Powder, Dried Mango
                      Powder, Garlic Powder, Onion Powder), Acidity Regulator
                      (INS 330), Flavour Enhancers(INS 627, INS 631) Natural &
                      Nature identical Flavoring Substances, Asafoetida and
                      Anticaking agent (INS551)
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Advantages</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>100% VEGETARIAN:</li>
                      <li>100% VEGETARIAN:</li>
                      <li>Hygienic</li>
                      <li>Less Perishable</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="mt-3">
              <button className="btn border"> Shop Now </button>
            </div>
          </div>
        </div>
      </div> */}

      <SingleProductsDetails
        productheading="FunCure Masala Masti
        "
        productname="FunCure Masala Masti
        "
        productrate="5/-"
        productdiscription="Crunchy solid sticks in two flavors – FunCure Masala Masti for spice lovers and tangy tomato for Kids. The Masala Masti is a classic flavor with a great combination of spice and crunch. "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(43.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Corn Meal(17%), Gram Flour(4%) Refined Sugar Powder, Iodised Salt, Spice & Condiments(Tomato Powder, Chili Powder, Cumin Powder, Cinnamon Powder ,Dried Mango Powder, Garlic Powder, Onion Powder), Maltodextrin, Acidity Regulator (INS 330), Flavour Enhancers (INS 630, INS 631) Natural & Nature identical Flavoring Substances, Asafoetida and Anticaking agent (INS551)"
        productimg={fronchobreak}
      />
    </>
  );
};

export default Funcuremasalamsti;
