import Header from "./Component/Header";
import "../src/App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Footer from "./Component/Footer";
import ContactUs from "./Pages/ContactUs";
import About from "./Pages/About";
import Products from "./Pages/Products";
import Gallery from "./Pages/Gallery";
import ScrollToTop from "./Component/ScrollTop";
import ProductsDetails from "./Pages/ProductsDetails";
import Chipsandsticks from "./Pages/SubPages/Chipsandsticks";
import FriedSnacks from "./Pages/SubPages/FriedSnacks";
import Waahjiproducts from "./Pages/SubPages/Waahjiproducts.js";
import ExtrudedPuffandRings from "./Pages/SubPages/ExtrudedPuffandRings";
import PastaMasalaMunch from "./Pages/ProductsPages/Pasta-Masala-Munch";
import MasalaPipe from "./Pages/ProductsPages/Masala-Pipe";
import Funcuremasalamsti from "./Pages/ProductsPages/FunCure-Masala-Masti";
import TomatoChips from "./Pages/ProductsPages/TomatoChips";
import FunLeoCocktail from "./Pages/ProductsPages/FunLeoCocktail";
import Funpolotangytomato from "./Pages/ProductsPages/Funpolotangytomato";
import SomeshaMixture from "./Pages/ProductsPages/SomeshaMixture";
import FunLeoChineaseMasala from "./Pages/ProductsPages/FunLeoChineaseMasala";
import Frenchobreak from "./Pages/ProductsPages/FRENCHOBAKE";
import FunLeoKhathaMetha from "./Pages/ProductsPages/FunLeoKhathaMetha";
import FUNCURETANGYTOMATO from "./Pages/ProductsPages/Funcuretangytomato";
import Saltedchips from "./Pages/ProductsPages/Saltedchips";
import Onianchips from "./Pages/ProductsPages/Onianchips";
import Masalachips from "./Pages/ProductsPages/Masalachips";
import FunLeotangy from "./Pages/ProductsPages/FunLeotangy";
import FunLeoMagicMasala from "./Pages/ProductsPages/FunLeoMagicMasala";
import SaltedPipe from "./Pages/ProductsPages/SaltedPipe";
import Chopsymast from "./Pages/ProductsPages/Chopsymast";
import WifiCone from "./Pages/ProductsPages/WifiCone";
import Waahjistix from "./Pages/ProductsPages/waahjistix";
import Waahjichips from "./Pages/ProductsPages/Waahjichips";
import Waahjisev from "./Pages/ProductsPages/Waahjisev";
import Waahjimonkey from "./Pages/ProductsPages/Waahjimonkey";
import Waahjimastsnax from "./Pages/ProductsPages/Waahjimastsnax";
import Waahjisnaxring from "./Pages/ProductsPages/waahjisnaxring";
import Mstmstcrunchibite from "./Pages/ProductsPages/Mstmstcrunchibite";
import MastSnax from "./Pages/ProductsPages/MastSnax";
import Events from "./Pages/Events";
import CorporateVideo from "./Pages/CorporateVideo";
import CSR from "./Pages/CSR";
import OurProcess from "./Pages/OurProcess";
import Upcomming from "./Pages/SubPages/Upcomming";
import { ToastContainer } from "react-toastify";
import InvestorRelations from "./Pages/IndustrialVisit";
import PopcornClassicButter from "./Pages/PopcornClassicButter";
import MasalaNoodles from "./Pages/ProductsPages/MasalaNoodles";
import PastaTandooriMunch from "./Pages/ProductsPages/PastaTandooriMunch";
import EventMeet from "./Pages/EventMeet";
import Carrer from "./Pages/Carrer";
import Distributor from "./Pages/Distributor";
import LeaderShip from "./Component/LeaderShip";

function App() {
  const location = useLocation();
  const webUrls = location.pathname === "/industrial-visit"
  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/leadership" element={<LeaderShip />} />
        <Route path="/industrial-visit" element={<InvestorRelations />} />
        <Route path="/csr" element={<CSR />} />
        <Route path="/our-process" element={<OurProcess />} />
        <Route path="/products" element={<Products />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/events" element={<Events />} />
        <Route path="/corporate-video" element={<CorporateVideo />} />
        <Route path="/products-detail" element={<ProductsDetails />} />
        {/* //subpages */}
        <Route path="/chips-and-sticks" element={<Chipsandsticks />} />
        <Route path="/puf-and-rings" element={<ExtrudedPuffandRings />} />
        <Route path="/fried-snacks" element={<FriedSnacks />} />
        <Route path="/waahji-products" element={<Waahjiproducts />} />
        <Route path="/upcomming-products" element={<Upcomming />} />
        {/* //products pages  */}
        <Route path="/funcure-tangy-tomato" element={<FUNCURETANGYTOMATO />} />
        <Route path="/pasta-masala-munch" element={<PastaMasalaMunch />} />
        <Route path="/masala-pipe" element={<MasalaPipe />} />
        <Route path="/funcure-masala-masti" element={<Funcuremasalamsti />} />
        <Route path="/tomato-chips" element={<TomatoChips />} />
        <Route path="/salted-chips" element={<Saltedchips />} />
        <Route path="/onion-chips" element={<Onianchips />} />
        <Route path="/masala-chips" element={<Masalachips />} />
        <Route path="/fun-leo-cocktail" element={<FunLeoCocktail />} />
        <Route path="/fun-polo-tangy-tomato" element={<Funpolotangytomato />} />
        <Route path="/samosa-mixture-masala" element={<SomeshaMixture />} /> 
        <Route path="/pasta-tandoori-munch" element={<PastaTandooriMunch />} /> 
        <Route path="/popcorn-classic-butter" element={<PopcornClassicButter/>} />
        <Route
          path="/fun-leo-chinese-flavour"
          element={<FunLeoChineaseMasala />}
        />
        <Route path="/french-o-bake" element={<Frenchobreak />} />
        <Route path="/fun-leo" element={<FunLeoKhathaMetha />} />
        <Route path="/fun-leo-tangy-tomato" element={<FunLeotangy />} />
        <Route path="/fun-leo-magic-masala" element={<FunLeoMagicMasala />} />
        <Route path="/salted-pipe" element={<SaltedPipe />} />
        <Route path="/chopcy-mast-masala" element={<Chopsymast />} />
        <Route path="/wifi-cone-crunchy-n-crispy" element={<WifiCone />} />
        <Route
          path="/mast-mast-crispy-and-crunchy-bite"
          element={<Mstmstcrunchibite />}
        />
        <Route path="/waahji-stix" element={<Waahjistix />} />
        <Route path="/waahji-chips" element={<Waahjichips />} />
        <Route path="/sev-murmure" element={<Waahjisev />} />
        <Route path="/monkey-men" element={<Waahjimonkey />} />
        <Route path="/mast-snax-ring" element={<Waahjisnaxring />} />
        <Route path="/chinese-noodles" element={<Waahjimastsnax />} />
        <Route path="/mast-snax" element={<MastSnax />} />  
        <Route path="/event-meet" element={<EventMeet/>} /> 
        {/* <Route path="/popcorn-classic-butter" element={<PopcornClassicButter />} />  */}
        <Route path="/masala-noodles" element={<MasalaNoodles />} /> 
        <Route path="/career" element={<Carrer />} /> 
        <Route path="/distributor" element={<Distributor/>} />
      </Routes>
      <Footer mrgnTop={webUrls && `pt-0`}/>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
