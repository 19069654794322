import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail"; 
import funleomagic from "../../Images/products/06.png"

const FunLeoMagicMasala = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="FUN LEO Magic MASALA
         "
        productname="FUN LEO Magic MASALA
         "
        productrate="5/-"
        productdiscription="Fun Leo is the new product of ShyamG Snacks Comes in different flavors. These snacks are full of carbohydrates and protein, and they are a very good source of Energy. The use of Extra Masala in Fun Leo Magic Masala makes it tastier. These types of snakes are made for people of every age group. Try the latest product of ShyamG Snacks.

         "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(65.3%), Corn Meal(7.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil),
        Sugar, Edible Common Salt, Spice & Condiments(Chilly Powder, Coriander Powder, Cumin Powder, Ginger Powder, Turmeric Powder,
            Fenugreek Powder, Black pepper Powder, Nutmeg Fennel, Clove Powder,
            Cinnamon Powder, Aniseed Powder, Cardamom Powder, Garlic Powder, Onion Powder, Mango Powder) Flavour Enhancer (INS 627, INS 631) Natural & Nature identical Flavoring Substances,
            Acidity Regulator (INS 330, INS 296), Yeast Extract, Anticaking agent (INS551)
            "
        productimg={funleomagic}
      />
    </>
  );
};

export default FunLeoMagicMasala;
