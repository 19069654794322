import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners } from '../Reducers/CommonReducers';


function SpringSlider() {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    dispatch(getBanners())
  },[])
  const { getBannersList } = useSelector(
    (state) => state.commonReducer
  );
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  }; 
  const width = useRef(window.innerWidth);
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {
        !!getBannersList && getBannersList?.map((data, index) => {
          return(
            <Carousel.Item key={index}>
            <img className="d-block w-100" alt={data?.name} src={width.current > 500 ? `http://shyamg.havflyinvitation.com/${data?.desktop}` : `http://shyamg.havflyinvitation.com/${data?.phone}` } />
            </Carousel.Item>  
          )
        })
      }
    </Carousel>
  );
}

export default SpringSlider;