import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import crunchibite from "../../Images/products/new-shymG-produdts/7.png";

const Mstmstcrunchibite = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Mast Mast Crispy And Crunchy Bite"
        productname="Mast Mast Crispy And Crunchy Bite"
        productrate="5/-"
        productdiscription="Ever thought to try crunchy Mast Mast with delicious tanginess? Mast Mast is a finger-licking crunchy snack. It is very popular among children. This crispiness of the Mast Mast never goes out of style."
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Wheat Flour (Maida),Rice Flour,
      Edible Vegetable Oil (Palmolein Oil or Cotton Seed Oil or Rice Bran Oil),
      Tapioca Starch,
      Maize,
      Sugar,
      Iodised Salt,
      Spices & Condiments (Chilly Powder, Fennel, Clove, Cinnamon Powder, Dried Mango Powder, Garlic Powder, Onion Powder, Tomato Powder) Acidity Regulator (INS 330, INS 500ii),
      Flavour Enhancer (INS 627, 631) Natural & Nature Identical Flavoring Substances,
      Anti-caking Agent (INS 551)
      "
        productimg={crunchibite}
      />
    </>
  );
};

export default Mstmstcrunchibite;
