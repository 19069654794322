import React from "react";
import Accordion from "react-bootstrap/Accordion";

const SingleProductsDetails = (props) => {
  const {
    productname,
    productrate,
    productdiscription,
    productdiscriptiontwo,
    productingredients,
    productimg,
  } = props;
  return (
    <>
      <div className="container mt-4">
        <div className="row align-item-center">
          <div className="col-md-6 text-center">
            <img className="phone-img w-75" src={productimg} alt="" />
          </div>

          <div className="col-md-6">
            <h2>
              <b>{productname}</b>
            </h2>
            <div>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
            </div>
            {/* <div className="product-rate">
              <h2>{productrate}</h2>
            </div> */}
            <div className="mt-3">
              <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Discription</Accordion.Header>
                  <Accordion.Body>
                    <p>{productdiscription} </p>
                    <p>{productdiscriptiontwo} </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Ingredients</Accordion.Header>
                  <Accordion.Body>{productingredients}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Advantages</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>100% VEGETARIAN:</li>
                      <li>Source of Fiber</li>
                      <li>Hygienic</li>
                      <li>Less Perishable</li>
                      <li>Less Perishable</li>
                      <li>Amazing Flavors</li>
                      <li>Made in India</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleProductsDetails;
