import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail";
import masachips from "../../Images/products/new-shymG-produdts/17.png";

const Masalachips = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Masala Chips
                 "
        productname="Masala Chips
                 "
        productrate="5/-, 10/-
                 "
        productdiscription="ShyamG Magic Masala flavor blend will excite your taste buds. Made up of the highest quality, farm-grown potatoes. A versatile snake that fits every occasion and every time. We offer these Masala Chips in air-tight packaging to retain them hygienic for a long time.

                 "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Potato(89%),Edible Vegetable Oil(Palmolein oil, Cottonseed Oil),
        Spices & condiments(onion powder, chili Powder, Dried Mango Powder, Coriander Powder, Ginger Powder, Garlic Powder, Black Pepper powder, spices Extract, Turmeric Powder)
        Edible Common Salt,
        Black Salt,
        Sugar Powder,
        Tomato Powder,
        Natural & Nature Identical Flavoring Substances and Acidity Regulator.

        "
        productimg={masachips}
      />
    </>
  );
};

export default Masalachips;
