import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/shrilogo.png";
import teamone from "../Images/team/UOD_4176-min-scaled.jpg";
import teamtwo from "../Images/team/UOD_4188-1-scaled.jpg";
import teamthree from "../Images/team/WhatsApp-Image-2023-02-23-at-8.05.20-PM.jpeg";

const LeaderShip = () => {
  return (
    <>
      <div className="footer-section">
        <div className="team-member">
          <div className="container">
            <div className="text-center">
              <h2 className=" mt-3 exp mb-4">MEET OUR LEADERSHIP</h2>
            </div>
            <div className="row">
              <div className="col-md-4 mt-2">
                <div className="inner-section team-bg p-2  shadow">
                  <img className="team-img custom-img" src={teamthree} alt="" />
                  <div className="mt-3 text-center">
                    <h2 className="mb-2">Mr. Amit Singal</h2>
                    <h6 className="mb-2">CHIEF EXECUTIVE OFFICER</h6>
                    <div className="border-design text-center mb-2"> </div>
                    <p>
                      Over 27 years of Experience out of which 20+ years in Food
                      Industry
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <div className="inner-section team-bg p-2">
                  <img className="team-img custom-img" src={teamone} alt="" />
                  <div className="mt-3 text-center">
                    <h2 className="mb-2">Mr. Parveen Singal</h2>
                    <h6 className="mb-2">MANAGING DIRECTOR</h6>
                    <div className="border-design text-center mb-2"> </div>
                    <p>
                      Over 22 years of Experience out of which 20+ years in Food
                      Industry
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mt-2">
                <div className="inner-section team-bg p-2">
                  <img className="team-img custom-img" src={teamtwo} alt="" />
                  <div className="mt-3 text-center">
                    <h2 className="mb-2">Mr. Arun Singal</h2>
                    <h6 className="mb-2">CHIEF OPERATING OFFICER</h6>
                    <div className="border-design text-center mb-2"> </div>
                    <p>
                      Over 24 years of Experience out of which 20+ years in Food
                      Industry
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderShip;
