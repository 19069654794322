import React from 'react';  
import { Col, Row } from "react-bootstrap";
import { EventImages,EventImages2 } from "./ProductsPages/Images";

const EventMeet = () => {
  return (
      <> 
            <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Dealers Meet Events</h2>
          </div>
        </div>
      </div> 
          
          <div className='container mt-4'> 
          <Row> 
          {EventImages.map((image) =>
             <Col md={3}>
              <img 
                target="_blank"
                 src={image}
                 className="w-100 shadow-1-strong rounded mb-4"
                 alt="Boat on Calm Water"
               />
             </Col>
         
       )}
        </Row>
          </div>
         
      
    </>
  )
}

export default EventMeet
