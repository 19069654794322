import React, {state, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import { addContact } from "../Reducers/CommonReducers";
// import {
//   getStatesList,
//   getDistrictList,
//   ContactForm,
// } from "../reducers/commonReducer";

const schema = yup.object().shape({
    userName: yup.string().required(),
  number: yup.string().min(10).max(10).required(),
  email: yup.string(),
  town: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  subject: yup.string(),
  message: yup.string().required(),
});

function ContactForm() {
  const width = useRef(window.innerWidth);
  const dispatch = useDispatch();
  return (
    <>
      {/* <div className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1"> */}
              <div className={`bg-white ${width.current > 500 ? `p-5` : `p-2`}`}>
                <Row className="">
                  <h2>
                  Please fulfill the form below.
                  </h2>
                </Row>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                      dispatch(addContact(values))
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    userName: "",
                      number: "",
                      email: "",
                      state: "",
                      city: "",
                      town:'',
                      subject: "",
                      message: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row >
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                name="userName"
                                value={values.userName}
                                onChange={handleChange}
                                isInvalid={!!errors.userName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.userName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                        <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="State"
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                isInvalid={!!errors.state}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.state}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="city"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={!!errors.city}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.city}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Town</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Town"
                                name="town"
                                value={values.town}
                                onChange={handleChange}
                                isInvalid={!!errors.town}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.town}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>
                                Mobile Number
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Mobile Number"
                                name="number"
                                value={values.number}
                                onChange={handleChange}
                                isInvalid={!!errors.number}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.number}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>Subject</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Subject"
                                name="subject"
                                value={values.subject}
                                onChange={handleChange}
                                isInvalid={!!errors.subject}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.subject}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          </Row>
                        <Row>
                          <Col md={12} sm={12} className="mb-3">
                            <Form.Group >
                              <Form.Label>
                                Write Your Message.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Your Message...."
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                isInvalid={!!errors.message}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit" className="cards-btn">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            {/* </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ContactForm;
