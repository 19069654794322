import React from "react";
import SingleProductsDetails from "../../Component/SingleProductDetail"; 
import waahjisev from "../../Images/new waah ji/Sev Murmure.png"

const Waahjisev = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Sev Murmure
         "
        productname="Sev Murmure
         "
        productrate="5/-"
        productdiscription="WAAHJI Sev Murmure snacks are extruded baked spiced authentic Indian snack. It is made by imported extruded machines. It consists of rice, edible oil, salt & spices. All spices & other ingredients are fresh & natural. It is packed in clean & hygienic environment on automatic packaging machines.

         "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(43.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil), Corn Meal(17%), Gram Flour(4%) Refined Sugar Powder, Iodised Salt, Spice & Condiments(Tomato Powder, Chili Powder, Cumin Powder, Cinnamon Powder ,Dried Mango Powder, Garlic Powder, Onion Powder), Maltodextrin, Acidity Regulator (INS 330), Flavour Enhancers (INS 630, INS 631) Natural & Nature identical Flavoring Substances, Asafoetida and Anticaking agent (INS551)"
        productimg={waahjisev}
      />
    </>
  );
};

export default Waahjisev;
