import React from "react";
import funcure from "../../Images/products/08.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const FunLeoKhathaMetha = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="FUN LEO Khatta Meetha
         "
        productname="FUN LEO Khatta Meetha
        "
        productrate="5/-"
        productdiscription="Fun Leo is the new product of ShyamG Snacks Comes in different flavors. These snacks are full of carbohydrates and protein, and they are a very good source of Energy. The taste of Tomato is best for the Khatta Meetha flavor of Fun Leo makes it tastier. These types of snakes are made for people of every age group. Try the latest product of ShyamG Snacks.

        "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Rice Meal(65.3%), Corn Meal(7.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil),
        Sugar, Edible Common Salt, Spice & Condiments( Coriander Powder, Ginger Powder, Fennel,
          Chilly Powder, Black pepper Powder, Clove Powder,) Natural & Nature identical Flavoring Substances, Acidity Regulator (INS 330), Anticaking agent (INS551)
          "
        productimg={funcure}
      />
    </>
  );
};

export default FunLeoKhathaMetha;
