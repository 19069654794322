import React, { useRef } from "react";
import contact from "../Images/contact.jpg";
import ContactForm from "./ContactForm"; 
import { Link } from "react-router-dom";

const ContactUs = () => {
  const width = useRef(window.innerWidth);
  return (
    <>
      <div className="">
        <div className="row fixed-height-all-slider p-0 m-0">
          <div className="col-md-6 p-0 corner-sets">
            <img className="custom-img" src={contact} />
          </div>
          <div className="col-md-6 corner-sets p-0 round-card-content main-column">
            <div className="round-card w-100 h-100 child-coloum">
              <div className="cards-content">
                <h2>Shyam-G Snacks</h2>
                <p>
                  Welcome to <b>Shyam-G Snacks</b>, a leading manufacturer of
                  healthy and tasty snacks in North India. Our journey began
                  with a vision to create healthy snack options for people that
                  are not only tasty but also good for their well-being.
                </p>
                <p>
                  Shyam-G Snacks is a renowned name in the field of snack items.
                  Shree Shyam-G Snacks have a popular name in every household
                  across India. From kids to old age people all know the quality
                  and the taste of Shyam-G snacks and want to have these snacks.
                  People buy Shyam-G snack packets for their trips too.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3480.146747240966!2d75.63058477407749!3d29.27801997531843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3913d3d3687b6a93%3A0x3b7dfdde5ee7c20!2sSHREE%20SHYAM%20SNACKS%20FOOD%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1684152122562!5m2!1sen!2sin"
          width="100%"
          height={450}
          style={{ border: 0 }}
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>

      <div className="container contact-form-content">
        <div className="row">
          <div className={`col-md-5 ${width.current > 500 ? `mt-5` : `mt-2`}`}>
            <div className="form-bg p-5">
              <h2>GET IN TOUCH</h2>
              <p>
                Welcome to Shyam-G Snacks, a leading manufacturer of healthy and
                tasty snacks in North India. Our journey began with a vision to
                create healthy snack options for people that are not only tasty
                but also good for their well-being.
              </p>
              <div className="d-flex form-icon mt-2">
                <div className="col-2">
                  <i className="bi bi-buildings form-icons"></i>
                </div>
                <div className="col-9">
                  <h5><b>Head Office</b></h5>
                  <span>
                    Plot No.1, Shree Shyam
                    Snacks Food Park, Village Jagan, Tehsil Adampur, Hisar - 125052,
                    Haryana, India
                  </span>
                </div>
              </div>
              <div className="d-flex form-icon mt-2">
                <div className="col-2">
                  <i className="bi bi-geo-alt form-icons"></i>
                </div>
                <div className="col-9">
                  <h5><b>Branch Office - 1</b></h5>
                  <span>
                  Arazi No. 385-A, Rajaswa Gram, Chachendi Sachendi - 1<br/>
                    Kanpur - 209304, Uttar Pradesh, India
                  </span>
                </div>
              </div> 
              <div className="d-flex form-icon mt-2">
                <div className="col-2">
                  <i className="bi bi-geo-alt form-icons"></i>
                </div>
                <div className="col-9">
                  <h5><b>Branch Office - 2</b></h5>
                  <span>
                  716, Ghevra, New Delhi,<br/> West Delhi - 110081, India
                  </span>
                </div>
              </div>

              <div className="d-flex form-icon mt-2">
                <div className="col-2">
                <i className="bi bi-envelope form-icons"></i>
                </div>
                <div className="col-9">
                <span> info@shyamg.in</span>
                </div>
              </div> 
              <div className="d-flex form-icon mt-4">
                <div className="col-2">
                  <i className="bi bi-phone form-icons"></i>
                </div>
                <div className="col-9"> 
                  <Link to="tel:+91 99929-24365">  <span> +91 99929-24365</span> </Link> /
                  <Link to="tel:18001200490">  <span> 18001200490</span> </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-5">
            <ContactForm/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
