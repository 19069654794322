import React from "react";
import { Col, Row } from "react-bootstrap";
import {Link } from 'react-router-dom';
import career from "../Images/career.jpg";

const Carrer = () => {
  return (
    <>
      <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Careers with Shyam-G</h2>
          </div>
        </div>
      </div>

      <div className="container">
        <Row>
        <Col md={6} className="mt-3">
          <img className="w-100" src={career}/>
        </Col>
        <Col md={6}>
        <h2 className="mt-4 mb-4">
            {" "}
            Explore Exciting Career Opportunities at Shyam-G Snacks in India
          </h2>
          <p className="text-justify fs-5 mb-0">
            Are you ready to begin a flavorful journey in the snack industry?
            Shree Shyam Snacks Food Private Limited, a leading snacks food
            manufacturing company in India, welcomes you to join its dynamic
            team.
          </p>
          <p className="text-justify fs-5 mb-0">
            Shyam-G Snacks has earned a stellar reputation for its top-quality
            snack products. We prioritize quality and hygiene, using only the
            finest raw materials to craft our delectable range. At Shyam-G, we
            offer diverse products, promising a satisfying snacking experience
            for all.
          </p>
          <p className="text-justify fs-5">
            If you're passionate about delivering excellence in snacks and want
            to contribute to our nationwide presence, explore career
            opportunities with us. Join Shyam-G Snacks and be a part of India's
            snack revolution.
                  </p>
            <div>
            <b></b><Link to="https://info.shyamg.in/career">
                <button className="m-auto apply-btn blink">Careers with Shyam-G Click Here</button> 
                </Link>
            </div>
        </Col>
        </Row>  
      </div>
    </>
  );
};

export default Carrer;
