import React from "react";
import { Link } from "react-router-dom";

const Product = (props) => {
  const { productsimg, productsname, productsprice, url } = props;
  return (
    <>
      <div className="p-2">
        <Link to={url}>
          <div className="products-img text-center custom-img products-single ">
            <img className="custom-img" src={productsimg} alt={productsname} />
          </div>
          <div className="products-title text-center  ">
            <h4 className=" w-100 mt-3  "> {productsname} </h4>
            <p className="text-center ">
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
              <i className="bi bi-star-fill text-warning"></i>
            </p>
            <h3 className="mt-2 zoom-in-zoom-out">{productsprice}</h3>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Product;
