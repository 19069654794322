import FUNCURETANGYTOMATO from "../Images/products/new-shymG-produdts/9.png";
import FUNCUREMASALAMASTI from "../Images/products/new-shymG-produdts/10.png";
import TOMATOCHIPS from "../Images/products/new-shymG-produdts/14.png";
import MASALACHIPS from "../Images/products/new-shymG-produdts/15.png";
import ONIONCHIPS from "../Images/products/new-shymG-produdts/16.png";
import ONION from "../Images/products/new-shymG-produdts/17.png";
import FUNCURETANGYTOMATOs from "../Images/products/05-1.png";
import FUNPOLO from "../Images/products/new-shymG-produdts/11.png";
import SAMOSAMIXTURE from "../Images/products/new-shymG-produdts/2.png";
import KHATTAMEETHA from "../Images/products/08.png";
import FRENCHBAKE from "../Images/products/new-shymG-produdts/12.png";
import FUNLEOTANGYTOMATO from "../Images/products/07.png";
import FUNLEOMAGICMASALA from "../Images/products/06.png";
import FUNLEOCHINESEMASALA from "../Images/products/09.png";
import MASALAPIPE from "../Images/resized images/Pipe Masala.png";
import PASTAMASALAMUNCH from "../Images/products/new-shymG-produdts/6.png";
import pasta from "../Images/products/new-shymG-produdts/6.png";
import SALTEDPIPE from "../Images/products/new-shymG-produdts/4.png";
import CHOPCYMASTMASALA from "../Images/products/new-shymG-produdts/13.png";
import WIFICONE from "../Images/products/new-shymG-produdts/1.png";
import MASTMAST from "../Images/products/new-shymG-produdts/7.png";
import Stix01 from "../Images/new waah ji/Stix01.png";
import waahjibluechips from "../Images/new waah ji/Chips Blue Packet.png";
import SevMurmure from "../Images/new waah ji/Sev Murmure.png";
import WaahjiMastSnax from "../Images/new waah ji/Waahji Mast Snax.png";
import WaahjiMastSnaxBlue from "../Images/new waah ji/Waahji Mast .png";
import noodles from "../Images/new waah ji/Noodles -01.png";
import MonkayMenRed from "../Images/new waah ji/Monkay Men Red.png"; 
import popcorn from "../Images/products/new-shymG-produdts/3.png"; 
import masalanoodles from "../Images/products/new-shymG-produdts/8.png"; 
import Pastatandori from "../Images/products/new-shymG-produdts/5.png";


// cat:- shyamg, puffRings, fried
export const productsDetailsarray = [
  {
    id: 1,
    name: "FUNCURE TANGY TOMATO",
    productsprice: "5/-",
    url: "/funcure-tangy-tomato",
    img: FUNCURETANGYTOMATO,
    cat: "shyamg",
  },
  {
    id: 2,
    name: "FUNCURE MASALA MASTI",
    productsprice: "5/-",
    url: "/funcure-masala-masti",
    img: FUNCUREMASALAMASTI,
    cat: "shyamg",
  },
  // {
  //   id: 3,
  //   name: "MASALA PIPE",
  //   productsprice: "5/-",
  //   url: "/masala-pipe",
  //   img: MASALAPIPE,
  //   cat: "fried",
  // },
  {
    id: 4,
    name: "PASTA MASALA MUNCH",
    productsprice: "5/-",
    url: "/pasta-masala-munch",
    img: PASTAMASALAMUNCH,
    cat: "fried",
  },
  {
    id: 5,
    name: "TOMATO CHIPS",
    productsprice: "5/-, 10/-",
    url: "/tomato-chips",
    img: TOMATOCHIPS,
    cat: "shyamg",
  },
  {
    id: 6,
    name: "SALTED CHIPS",
    productsprice: "5/-, 10/-",
    url: "/salted-chips",
    img: MASALACHIPS,
    cat: "shyamg",
  },
  {
    id: 7,
    name: "ONION CHIPS",
    productsprice: "5/-, 10/-",
    url: "/onion-chips",
    img: ONIONCHIPS,
    cat: "shyamg",
  },
  {
    id: 8,
    name: "MASALA CHIPS",
    productsprice: "5/-, 10/-",
    url: "/masala-chips",
    img: ONION,
    cat: "shyamg",
  },
  // {
  //   id: 9,
  //   name: "FUN LEO COCKTAIL",
  //   productsprice: "5/-",
  //   url: "/fun-leo-cocktail",
  //   img: FUNCURETANGYTOMATOs,
  //   cat: "puffRings",
  // },
  // {
  //   id: 10,
  //   name: "FUN LEO CHINESE MASALA",
  //   productsprice: "5/-",
  //   url: "/fun-leo-chinese-flavour",
  //   img: FUNLEOCHINESEMASALA,
  //   cat: "puffRings",
  // },
  {
    id: 11,
    name: "SALTED PIPE",
    productsprice: "5/-",
    url: "/salted-pipe",
    img: SALTEDPIPE,
    cat: "fried",
  },
  // {
  //   id: 12,
  //   name: "FUN LEO MAGIC MASALA",
  //   productsprice: "5/-",
  //   url: "/fun-leo-magic-masala",
  //   img: FUNLEOMAGICMASALA,
  //   cat: "puffRings",
  // },
  // {
  //   id: 13,
  //   name: "FUN LEO TANGY TOMATO",
  //   productsprice: "5/-",
  //   url: "/fun-leo-tangy-tomato",
  //   img: FUNLEOTANGYTOMATO,
  //   cat: "puffRings",
  // },
  {
    id: 14,
    name: "FRENCH ‘O’ BAKE",
    productsprice: "5/-",
    url: "/french-o-bake",
    img: FRENCHBAKE,
    cat: "puffRings",
  },
  // {
  //   id: 15,
  //   name: "FUN LEO KHATTA MEETHA",
  //   productsprice: "5/-",
  //   url: "/fun-leo",
  //   img: KHATTAMEETHA,
  //   cat: "puffRings",
  // },
  {
    id: 16,
    name: "SAMOSA MIXTURE",
    productsprice: "5/-",
    url: "/samosa-mixture-masala",
    img: SAMOSAMIXTURE,
    cat: "puffRings",
  },
  {
    id: 17,
    name: "FUN POLO TANGY TOMATO",
    productsprice: "5/-",
    url: "/fun-polo-tangy-tomato",
    img: FUNPOLO,
    cat: "puffRings",
  },
  {
    id: 18,
    name: "CHOPCY MAST MASALA",
    productsprice: "5/-",
    url: "/chopcy-mast-masala",
    img: CHOPCYMASTMASALA,
    cat: "fried",
  },
  // {
  //   id: 19,
  //   name: "PASTA MASALA MUNCH",
  //   productsprice: "10/-",
  //   url: "/pasta-masala-munch",
  //   img: pasta,
  //   cat: "fried",
  // },
  {
    id: 20,
    name: "WIFI CONE CRUNCHY “N” CRISPY",
    productsprice: "5/-",
    url: "/wifi-cone-crunchy-n-crispy",
    img: WIFICONE,
    cat: "fried",
  },
  {
    id: 21,
    name: "MAST MAST CRISPY AND CRUNCHY BITE",
    productsprice: "5/-",
    url: "/mast-mast-crispy-and-crunchy-bite",
    img: MASTMAST,
    cat: "fried",
  },
];
//waahji
export const waahjiProductsArray = [
  // {
  //   id: 1,
  //   name: "STIX",
  //   productsprice: "5/-",
  //   url: "/waahji-stix",
  //   img: Stix01,
  //   cat: "waahji",
  // },
  // {
  //   id: 2,
  //   name: "CHIPS",
  //   productsprice: "5/-",
  //   url: "/waahji-chips",
  //   img: waahjibluechips,
  //   cat: "waahji",
  // },
  // {
  //   id: 3,
  //   name: "SEV MURMURE",
  //   productsprice: "5/-",
  //   url: "/sev-murmure",
  //   img: SevMurmure,
  //   cat: "waahji",
  // },
  // {
  //   id: 4,
  //   name: "MONKEY MEN",
  //   productsprice: "5/-",
  //   url: "/monkey-men",
  //   img: MonkayMenRed,
  //   cat: "waahji",
  // },
  // {
  //   id: 5,
  //   name: "MAST SNAX",
  //   productsprice: "5/-",
  //   url: "/mast-snax",
  //   img: WaahjiMastSnax,
  //   cat: "waahji",
  // },
  // {
  //   id: 6,
  //   name: "MAST SNAX (RING)",
  //   productsprice: "5/-",
  //   url: "/mast-snax-ring",
  //   img: WaahjiMastSnaxBlue,
  //   cat: "waahji",
  // },
  // {
  //   id: 7,
  //   name: "CHINESE NOODLES",
  //   productsprice: "5/-",
  //   url: "/chinese-noodles",
  //   img: noodles,
  //   cat: "waahji",
  // }, 

  {
    id: 8,
    name: "POPCORN CLASSIC BUTTER",
    productsprice: "5/-",
    url: "/popcorn-classic-butter",
    img: popcorn,
    cat: "waahji",
  },

  {
    id: 9,
    name: "MASALA NOODLES",
    productsprice: "5/-",
    url: "/masala-noodles",
    img:masalanoodles,
    cat: "waahji",
  }, 

  {
    id: 10,
    name: "PASTA TANDOORI MUNCH",
    productsprice: "5/-",
    url: "/pasta-tandoori-munch",
    img:Pastatandori,
    cat: "waahji",
  },
];
