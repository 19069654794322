
import React from "react";
import Tomatochips from "../Images/products/new-shymG-produdts/3.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../Component/SingleProductDetail";

const PopcornClassicButter = () => {
  return (
      <> 
    
      <SingleProductsDetails
        productheading="Popcorn Classic Butter"
        productname="Popcorn Classic Butter"
        productrate="5/-"
        productdiscription="These sweet and salty Popcorn Classic Butter with the irresistible flavor of tomatoes take you to the next level of taste. Popcorn Classic Butter taste of traditional Popcorn flavor."
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Pop corn (60%), Edible vegtable oil (Palmolein Oil or cotton Seed Oil or Rice Bran Oil),Ionized salt,Butter Flavor(Natural & Nature Identical Flavoring Subatances)."
        productimg={Tomatochips}
        />
        </>
  )
}

export default PopcornClassicButter
