import React from 'react'
import SingleProductsDetails from '../../Component/SingleProductDetail';
import funleotangy from "../../Images/products/07.png"

const FunLeotangy = () => {
  return (
    <div>
          <SingleProductsDetails
          
          productheading="Fun Leo Tangy Tomato
          "
          productname="Fun Leo Tangy Tomato
          "
          productrate="5/-"
          productdiscription="Ever think of trying the best Snacks of the time? FUN LEO TANGY TOMATO is the one with the delicious tanginess of Organic Tomatoes. It is also available in different Flavors. Try Fun Leo Tangy Tomato Now!

          "
          productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
          productingredients="Rice Meal(65.3%), Corn Meal(7.2%), Edible vegetable oil (Palmolein oil or Cotton Seed Oil or Rice Bran Oil),
          Sugar, Edible Common Salt, Starch,
          Spice & Condiments(Chilly Powder, Fennel, Clove Powder, Cinnamon Powder, Garlic Powder, Onion Powder, Tomato Powder)
          Flavour Enhancer (INS 627, INS 631) Natural & Nature identical Flavoring Substances, Acidity Regulator (INS 330),
          Anticaking agent (INS551).
          "
          productimg={funleotangy}/>
    </div>
  )
}

export default FunLeotangy
