import React from "react";
import { Col } from "react-bootstrap";
import { productsDetailsarray } from "../const";
import Product from "../../Component/Product";
const ExtrudedPuffandRings = () => {
  return (
    <>
      <div className="products-category products-section">
        <div className="container">
          <h2 className="text-center fs-1">Extruded Puff and Rings</h2>
          <div className="border-design  text-center mb-2"> </div>
          <div className="row">
            {productsDetailsarray.map((data, index) => {
              return (
                data?.cat?.match("puffRings") && (
                  <Col md={4} key={index}>
                    <Product
                      productsimg={data?.img}
                      productsname={data?.name}
                      // productsprice={data?.productsprice}
                      url={data?.url}
                    />
                  </Col>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtrudedPuffandRings;
