import React from "react";
import funcure from "../../Images/products/new-shymG-produdts/2.png";
import Accordion from "react-bootstrap/Accordion";
import SingleProductsDetails from "../../Component/SingleProductDetail";

const SomeshaMixture = () => {
  return (
    <>
      <SingleProductsDetails
        productheading="Samosa Mixture
         "
        productname="Samosa Mixture
         "
        productrate="5/-"
        productdiscription=" ShyamG offers a great variety of delicious Indian snacks which spoon out the irresistible natural taste and wholesome nutritious munch, Samosa Mixture is one of them. It is available in mouth-watering Onion and garlic flavors. It is in the mixed shape of Small crispy Sticks and a small Size Samosa.

         "
        productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
        productingredients="Spices And Condiments (Chili Powder, Coriander, Turmeric Powder, Cumin, Black Pepper, Ginger Powder, Fennel, Ajwain, Clove, Cinnamon, Aniseed Cardamom Nutmeg, Garlic Powder, Onion Powder, Dried Mango Powder),Flavor Enhancer (INS 627, INS 631) Natural & Nature Identical Flavoring Substance,
        Asafoetida,Oregano,Methi Leaf, Acidity Regulator (INS 330),"
        productimg={funcure}
      />
    </>
  );
};

export default SomeshaMixture;
