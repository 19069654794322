import React from "react";
import { Col,Button,Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { newsletterAdd } from "../Reducers/CommonReducers";

const schema = yup.object().shape({
    email: yup.string().required(),
  });
  
const NewsLetter = () => {
  const width = useRef(window.innerWidth);
  const dispatch = useDispatch();
  return (
    <>
      <div className={`m-auto ${width.current > 500 ? 'w-50' : 'w-100 mb-4'}`}>
        <div className="team-member">
          <div className="container">
            <div className="text-center">
              <h2 className=" mt-3 exp mb-4">SIGN UP FOR NEWSLETTER</h2>
              <p>Join now for exclusive updates, offers, and insights. Stay connected and informed with our newsletter. Subscribe today!</p>
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                dispatch(newsletterAdd(values))
                resetForm({ values: "" });
              }}
              initialValues={{
                email: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="m-auto">
                  <Col md={12}>
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          className="form-control border-radius"
                          placeholder="Enter Your Email"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button className="border-radius cards-btn w-100" type="submit">
                      Signup
                    </Button>
                  </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
