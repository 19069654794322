import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { clientReview } from "../Reducers/CommonReducers";

const Testimonial = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clientReview({
      counter: 15
    }))
  }, []);
  const { clientReviewList } = useSelector(
    (state) => state.commonReducer
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="testmoinal-slider">
        <div className="text-center">
          <h3 className="fs-1">
            <b>What Our Client Says</b>
          </h3>
        </div>

        <Slider {...settings}>
          {
            !!clientReviewList && clientReviewList?.map((data, index) => {
              return(
                <div key={index}>
                <div className="contianer">
                  <div className="d-flex cards g-2 m-auto">
                    <div className="col p-3">
                      <div className="cards-bg shadow icons position-relative p-3">
                        <div className="d-flex">
                          <div className="col-6">
                            <h2>{data?.name}</h2>
                            <p>{data?.profession}</p>
                            <div className="cards-border text-start mb-2"> </div>
                          </div>
    
                          <div className="col-6 text-end">
                            <i className="bi bi-star-fill text-warning"></i>
                            <i className="bi bi-star-fill text-warning"></i>
                            <i className="bi bi-star-fill text-warning"></i>
                            <i className="bi bi-star-fill text-warning"></i>
                            <i className="bi bi-star-fill text-warning"></i>
                          </div>
                        </div>
                        <div className="">
                          <p>
                          {data?.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            })
          }
        </Slider>
      </div>
    </>
  );
};

export default Testimonial;
