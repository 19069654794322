import React from "react";
import { Row } from "react-bootstrap";
import processbanner from "../Images/new-shyam-G.jpg";
import rawmaterial from "../Images/process/UOD_4325.jpg";
import fullyautomatedmachines from "../Images/fully-automated-machines.JPG";
import analyticallab from "../Images/process/UOD_4460.jpg";
import packaging from "../Images/process/UOD_4406.jpg";
import finallproduct from "../Images/process/UOD_4310.jpg";

const OurProcess = () => {
  const opts = {
    width: "100%",
    height: "240",
    // playerVars: {
    //   autoplay: 0,
    // },
  };
  return (
    <>
      <div className="">
        <div className="row fixed-height-all-slider p-0 m-0">
          <div className="col-md-12 p-0 corner-sets">
            <img className="custom-img" src={processbanner} />
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <Row>
          <h2 className="text-center fs-1">Our Process</h2>
          <p className="text-center text-justify fs-5">
            {" "}
            Our snack-making journey begins with the careful selection of raw
            materials. We firmly believe that exceptional snacks can only be
            crafted from the finest ingredients. To achieve this, we source our
            raw materials diligently, working closely with local farms and
            trusted vendors from every corner of India. It's at this initial
            stage that our commitment to quality takes root.
          </p>
        </Row>
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={rawmaterial} />
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Raw Material: Quality at the Core
            </h3>
            <p className="text-justify fs-5">
              At Shyam-G Snacks, the quest for quality starts with our chosen
              raw materials. We are uncompromising in our standards, opting only
              for the most wholesome ingredients available. Maintaining the
              freshness of these ingredients is crucial, which is why we employ
              hygienic storage facilities. For instance, we utilize specialized
              cold storage for potatoes, preserving their freshness and flavor.{" "}
            </p>
          </div>
        </Row>
        <Row className="mt-4 coloum-reverse">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Fully Automated Machines: Precision and Consistency
            </h3>
            <p className="text-justify fs-5">
              Precision becomes paramount once our top-tier ingredients arrive
              at our state-of-the-art manufacturing unit. We harness the power
              of cutting-edge, fully automated machines, each an engineering
              marvel. These machines come to life, expertly blending our
              handpicked ingredients with freshly ground spices and seasonings.
              What emerges is a symphony of flavors crafted according to our
              meticulously developed recipes. Automation isn't just about
              ensuring consistency; it's also about upholding the highest
              hygiene standards. Our snacks are free from harmful preservatives,
              a proof to our commitment to providing healthy and safe products.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={fullyautomatedmachines} />
          </div>
        </Row>
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={analyticallab} />
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Quality Assurance in the Analytical Lab: Expert Evaluation
            </h3>
            <p className="text-justify fs-5">
              Every Shyam-G Snacks product undergoes rigorous testing in our
              analytical lab, where experienced food technologists put each
              snack through its paces. Their expertise ensures that our products
              meet the highest quality and safety standards. It's our way of
              ensuring that every bite you take is delicious and trustworthy.{" "}
            </p>
          </div>
        </Row>
        <Row className="mt-4 coloum-reverse">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Packaging with Automatic Machines: Sealed for Freshness
            </h3>
            <p className="text-justify fs-5">
              Our dedication to crafting top-quality snacks extends to their
              packaging. Here, we employ cutting-edge automatic packaging
              machines, ensuring that our products remain untouched by human
              hands. This technological marvel not only guarantees the utmost
              freshness but also preserves the exceptional quality of our
              snacks. When you open a Shyam-G Snacks package, you're
              experiencing a product as fresh as the day it was made.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={packaging} />
          </div>
        </Row>
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <img className="w-100" src={finallproduct} />
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">
              Final Product: A Commitment to Excellence
            </h3>
            <p className="text-justify fs-5">
              Our commitment to quality and transparency is unwavering. Shyam-G
              Snacks' final products are a culmination of this commitment,
              carefully delivered to the market. They are ready to provide
              absolute delight your taste buds with their exceptional flavors
              and quality. Our snacks are more than just delicious; they are
              proof to the trust we have earned over the years.
            </p>
            <p className="text-justify fs-5">
              In every snack we create, we infuse our dedication to quality,
              hygiene, and taste. With Shyam-G Snacks, you're not just savoring
              a snack; you're experiencing the result of our unwavering
              commitment to excellence. We invite you to explore our range of
              delectable snacks, each crafted with care and devotion, and savor
              the taste of trust.
            </p>
          </div>
        </Row>
      </div>
    </>
  );
};

export default OurProcess;
