import img1 from "../../Images/event-images/0M4A2527.jpg";  
import img2 from "../../Images/event-images/0M4A2529.jpg"; 
import img3 from "../../Images/event-images/0M4A2559.jpg"; 
import img4 from "../../Images/event-images/0M4A2616.jpg"; 
import img5 from "../../Images/event-images/0M4A2659.jpg"; 
import img6 from "../../Images/event-images/0M4A2661.jpg"; 
import img7 from "../../Images/event-images/0M4A2663.jpg"; 
import img8 from "../../Images/event-images/0M4A2664.jpg"; 
import img9 from "../../Images/event-images/0M4A2665.jpg"; 
import img10 from "../../Images/event-images/0M4A2668.jpg"; 
import img11 from "../../Images/event-images/0M4A2743.jpg";  
import img12 from "../../Images/event-images/0M4A2744.jpg"; 
import img13 from "../../Images/event-images/0M4A2750.jpg"; 
import img14 from "../../Images/event-images/0M4A2884.jpg"; 
import img15 from "../../Images/event-images/0M4A2972.jpg"; 
import img16 from "../../Images/event-images/0M4A3090.jpg"; 
import img17 from "../../Images/event-images/0M4A3178.jpg"; 
import img18 from "../../Images/event-images/0M4A3240.jpg"; 
import img19 from "../../Images/event-images/0M4A3249.jpg"; 
import img20 from "../../Images/event-images/0M4A3256.jpg"; 


import img21 from "../../Images/event-images/0M4A3326.jpg"; 
import img22 from "../../Images/event-images/0M4A3345.jpg";
import img23 from "../../Images/event-images/0M4A3352.jpg";
import img24 from "../../Images/event-images/5E8A6077.jpg";
import img25 from "../../Images/event-images/5E8A6081.jpg";
import img26 from "../../Images/event-images/5E8A6086.jpg";
import img27 from "../../Images/event-images/5E8A6089.jpg";
import img28 from "../../Images/event-images/5E8A6093.jpg";
import img29 from "../../Images/event-images/5E8A6105.jpg";
import img30 from "../../Images/event-images/5E8A6113.jpg";
import img31 from "../../Images/event-images/5E8A6136.jpg";
import img32 from "../../Images/event-images/5E8A6495.jpg";
import img33 from "../../Images/event-images/5E8A6146.jpg";
import img34 from "../../Images/event-images/5E8A6149.jpg";
import img35 from "../../Images/event-images/5E8A6510.jpg";
import img36 from "../../Images/event-images/5E8A6157.jpg";
import img37 from "../../Images/event-images/5E8A6168.jpg";
import img38 from "../../Images/event-images/5E8A6175.jpg";
import img39 from "../../Images/event-images/5E8A6511.jpg";
import img40 from "../../Images/event-images/5E8A6296.jpg";



export const EventImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,img21, img22, img23, img24, img25, img26, img27,img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40]; 
