import React from 'react'
import SingleProductsDetails from '../../Component/SingleProductDetail' 
import chopsy from "../../Images/products/new-shymG-produdts/13.png"

const Chopsymast = () => {
  return (
    <>
          <SingleProductsDetails
          productheading="Chopcy Mast Masala
          "
          productname="Chopcy Mast Masala
          "
          productrate="5/-"
          productdiscription="Chopcy is very delicious. Snacks contain the taste of tomato, Lemon, and chili in it. Gives a tangy tomato taste while eating. The pack contains different shapes like stars, rings, Sticks Etc.

          "
          productdiscriptiontwo="Snacks are small services that delight the hunger of your stomach, generally the interval between meals. Shree ShyamG Snacks provides a variety of packaged fried snacks and other processed food, using fresh ingredients like home."
          productingredients="Refined Wheat Flour (Maida),Edible Vegetable Oil (Palmolein Oil, Cotton Seed Oil),
          Tapioca Starch,Potato Flakes,Sugar,Spices & Condiments (Chili Powder, Dried Mango Powder, Coriander Powder, Cumin Powder, Ginger Powder, Black Pepper Powder),
          Dehydrated Vegetable Powder (Onion, Garlic, Tomato, Tamarind), Salt, Acidity Regulator (Citric Acid (INS330), Malic Acid (INS296)), Maltodextrin, Anti-caking Agent (Silicone Dioxide (INS551)) And Sodium Benzoate (INS211)"
          productimg={chopsy}
          
          />
    </>
  )
}

export default Chopsymast
