import React from 'react'
import SingleProductsDetails from '../../Component/SingleProductDetail'
import stix from '../../Images/new waah ji/Stix01.png'

const Waahjistix = () => {
  return (
    <div>
          <SingleProductsDetails 
                 productheading="Stix
                 "
                 productname="Stix
                 "
                 productrate="5/-"
                 productdiscription="WAAHJI Stix, the delicious quality extruded sticks made by fully automatic American imported machines. These are corn, rice and gram based crunchy fried sticks with chatpata spicy and tomato flavours. This is liked by every age group people for its forever taste. The product is packed by hygienic & automatic packaging machines. All ingredients of Stix are natural and herbal"
                 productimg={stix}
          />
    </div>
  )
}

export default Waahjistix
