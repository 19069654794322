import React from "react";
import { Col, Row } from "react-bootstrap";
import YouTube from "react-youtube";
import shyamgevent from "../Images/events/shyamgevent.jpg";
import shyamgeventone from "../Images/events/shyamgeventone.jpg";
import shyamgeventcake from "../Images/events/shyamgeventcake.jpg";
import shyamgeventparty from "../Images/events/shyamgeventparty.jpg";
import d1 from "../Images/events/d1.JPG";
import d2 from "../Images/events/d2.JPG";
import d3 from "../Images/events/d3.JPG";
import d4 from "../Images/events/d4.JPG";
import p1 from "../Images/events/p1.JPG";
import p2 from "../Images/events/p2.JPG";
import p3 from "../Images/events/p3.JPG";
import p4 from "../Images/events/p4.JPG";

const Events = () => {
  const opts = {
    width: "100%",
    height: "350",
    // playerVars: {
    //   autoplay: 1,
    // },
  };
  return (
    <>
      <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Events</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-4">
        <Row className="mt-5">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h2 className="text-uppercase fs-1 fw-bold">Completed 15 Years</h2>
            <p className="text-justify fs-5">
              This remarkable milestone is a testament to the dedication, hard
              work, and unwavering commitment of the entire Shyam-G team.
              Over the past decade and a half, Shyam-G has not only
              established itself as a trusted brand but has also carved a
              special place in the hearts and taste buds of its loyal customers.
              From delectable snacks to mouthwatering treats, Shyam-G has
              consistently delivered quality and flavor that have become
              synonymous with the brand. As you celebrate this significant
              achievement, may the journey ahead be filled with even greater
              success, innovation, and continued satisfaction for your valued
              customers. Here's to many more years of snack-filled joy and
              prosperity!
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
          <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/Jqu88_58r6w"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </Row>
        <Row>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={shyamgevent}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={shyamgeventone}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={shyamgeventcake}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={shyamgeventparty}/>
          </Col>
        </Row>
        <hr/>
        <Row className="mt-5 coloum-reverse">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
          <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/3zanIBfP6bw"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h2 className="text-uppercase fs-1 fw-bold">
              Successfull Dealers Meet
            </h2>
            <p className="text-justify fs-5">
              The successful dealers' meet at Shyam-G was a resounding
              triumph, showcasing the brand's dedication to forging strong and
              enduring partnerships. Bringing together dealers from across
              regions, this event was a testament to the collaborative spirit
              that underpins Shyam-G's growth. The meet provided a platform
              for valuable insights, knowledge sharing, and networking,
              fostering an atmosphere of mutual trust and support. It not only
              celebrated the accomplishments of the past but also laid the
              foundation for an even more prosperous future. As Shyam-G
              continues to thrive and expand its horizons, it is evident that
              the bonds forged at this dealers' meet will play a pivotal role in
              its continued success, ensuring that its delicious snacks reach
              even more delighted customers in the years to come.
            </p>
          </div>
        </Row>
        <Row>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={d1}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={d2}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={d3}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={d4}/>
          </Col>
        </Row>
        <hr/>
        <Row className="mt-5">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h2 className="text-uppercase fs-1 fw-bold">
              Products Launch event
            </h2>
            <p className="text-justify fs-5">
              The products launch event at Shyam-G was an exciting and
              highly anticipated affair, marking a significant milestone in the
              company's journey. It was an opportunity for Shyam-G to unveil
              its latest offerings, which are sure to delight snack enthusiasts
              and tantalize taste buds. The event was a showcase of innovation,
              quality, and commitment to delivering exceptional snacking
              experiences. From savory to sweet, the new products demonstrated
              Shyam-G's versatility and dedication to meeting the evolving
              tastes of its valued customers. With enthusiastic attendees,
              tasteful presentations, and a palpable sense of anticipation, this
              launch event not only celebrated the brand's past success but also
              heralded a promising future filled with delicious surprises.
              Shyam-G continues to set the bar high in the snack industry,
              and its latest product launch event was a testament to its ongoing
              commitment to excellence.
            </p>
          </div>

          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
          <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/CFdz0YZNgzQ"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </Row>
        <Row>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={p1}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={p2}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={p3}/>
          </Col>
          <Col md={3} className="mb-1">
            <img className="w-100 img-shadows" src={p4}/>
          </Col>
        </Row>
        {/* <div className=" container Our-Gallery mt-5">
          <div className="text-center">
            {" "}
            <h2 className="text-upercase fs-1 fw-bold"> Our Gallery</h2>
            <p> </p>At Shyam-G Snacks, the quest for quality starts with our
            chosen raw materials.
          </div>

          <Row className="mt-4">
            <Col md={6} sm={12} xs={12}>
              <img className="w-100 img-shadows" src={imagone} alt="" />{" "}
            </Col>
            <Col md={6} sm={12} xs={12}>
              <Row className="">
                {" "}
                <Col className="phone-gap" md={6} sm={12} xs={12}>
                  <img className="w-100 img-shadows" src={imagtwo} alt="" />{" "}
                </Col>
                <Col className="phone-gap" md={6} sm={12} xs={12}>
                  <img className="w-100 img-shadows" src={imagthree} alt="" />{" "}
                </Col>{" "}
                <Col className="mt-3" md={6} sm={12} xs={12}>
                  <img className="w-100 img-shadows" src={imagfour} alt="" />{" "}
                </Col>
                <Col className="mt-3" md={6} sm={12} xs={12}>
                  <img className="w-100 img-shadows" src={imagfive} alt="" />{" "}
                </Col>{" "}
              </Row>
            </Col>
          </Row>

          <Row className="mt-4 text-center">
            <Link to="/event-meet">
              {" "}
              <span className="fs-4 fw-bold">
                {" "}
                View Our Gallery <i className="bi bi-chevron-double-right"></i>{" "}
              </span>{" "}
            </Link>
          </Row>
        </div> */}
      </div>
    </>
  );
};

export default Events;
