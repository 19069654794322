import React from "react";
import { Row } from "react-bootstrap";
import imgone from "../Images/Gallery/processbanner.jpg";
import imgtwo from "../Images/Gallery/UOD_4139-scaled.jpg";
import imgthree from "../Images/Gallery/UOD_4254-scaled.jpg";
import four from "../Images/Gallery/UOD_4268-scaled.jpg";
import five from "../Images/Gallery/UOD_4295-scaled.jpg";
import six from "../Images/Gallery/UOD_4297-scaled.jpg";
import seven from "../Images/Gallery/UOD_4303-scaled.jpg";
import eight from "../Images/Gallery/UOD_4349-scaled.jpg";
import nine from "../Images/Gallery/UOD_4381-scaled.jpg";
import ten from "../Images/Gallery/UOD_4406-scaled.jpg";
import eleven from "../Images/Gallery/UOD_4408-scaled.jpg";

const CorporateVideo = () => {
    const opts = {
        width: '100%',
        height: '370',
        // playerVars: {
        //   autoplay: 0,
        // },
      };
  return (
    <>
      <div className="contact-us-bg">
        <div className="col-md-12 p-0 corner-sets">
          <div className="container text-white pt-5 pb-5">
            <h2 className="fs-1 text-center">Corporate Infrastructure</h2>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <Row className="mt-4">
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
          <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/13KIzMrMzyA"
              frameborder="0"
              allowfullscreen
            ></iframe>
          {/* <YouTube videoId={`13KIzMrMzyA`} opts={opts} /> */}
          </div>
          <div className="col-lg-6 col-md-6 mb-6 mb-lg-0">
            <h3 className="main-poin-heading">Shyam-G Corporate Infrastructure</h3>
            <p className="text-justify"> We value originality in our products, and our team of experts works hard to create unique and delicious flavors that are sure to impress. We use only certified and tested food ingredients, ensuring that our snacks are free from any harmful additives or preservatives. We also take responsibility for the environment and ensure that our manufacturing processes are sustainable and eco-friendly. Our team works hard to minimize waste and reduce our carbon footprint, as we believe that every step towards a greener world counts.</p> 
            <p className="text-justify">To provide a variety of the best snacks to our customers we use the latest technology. We are constantly engaged in manufacturing and supplying assortments such as Mast Mast, Pasta, Wifi Cones, Samosa Mixture, Natoore, Fun Polo, and much more. Our production units have been designed to meet all international standards within the food industry. We use the best quality raw material to manufacture all our products according to the user’s taste.</p>
          </div>
        </Row>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
              <img
                src={imgone}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
              <img
                src={imgtwo}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Wintry Mountain Landscape"
              />
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src={imgthree}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Mountains in the Clouds"
              />
              <img
                src={four}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Boat on Calm Water"
              />
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src={five}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />
              <img
                src={imgthree}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src={six}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />
              <img
                src={seven}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>

            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src={nine}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />
              <img
                src={eight}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <img
                src={ten}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Waves at Sea"
              />
              <img
                src={eleven}
                className="w-100 shadow-1-strong rounded mb-4"
                alt="Yosemite National Park"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateVideo;
