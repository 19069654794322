import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
} from "../helpers/apiStructure";

const initialState = {
  // Dashboard
  clientReviewList: [],
  getBannersList:[],
};

var baseUrl = "http://shyamg.havflyinvitation.com";

//website basic
export const addContact = createAsyncThunk("addContact", async (body) => {
    const result = await postApi(
      `${baseUrl}/api/contact/create`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  });
  //banners
export const getBanners = createAsyncThunk("getBanners", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/latest/banners`,
    "post",
    body
  );
  return result;
});
//visits
export const addIndustrialVisit = createAsyncThunk("addIndustrialVisit", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/industrialvisit/add`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//review
export const clientReview = createAsyncThunk("clientReview", async (body) => {
  const result = await postApi(`${baseUrl}/api/review/list`, "post", body);
  return result;
});
//newslatter
export const newsletterAdd = createAsyncThunk("newsletterAdd", async (body) => {
  const result = await postApi(`${baseUrl}/api/newslatter/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});


  // export const updateComplaint = createAsyncThunk("updateComplaint", async (body) => {
  //   const result = await postApiFile(
  //     `${baseUrl}/api/complaint/edit/${body?.id}`,
  //     "put",
  //     body
  //   );
  //   await messages(result?.message, result?.status);
  //   return result;
  // });



const userReducer = createReducer(
  {},
  (builder) => {
    builder
      // demoooooo
      .addCase(clientReview.fulfilled, (state, action) => {
        state.clientReviewList = action.payload;
      })
      // Dashboard
      .addCase(getBanners.fulfilled, (state, action) => {
        state.getBannersList = action.payload;
      })
  }
);

export default userReducer;
